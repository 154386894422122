import React, { useMemo, useState } from "react"

import { Box, Grid, Tooltip, Typography } from "@mui/material"

import { Schedule } from "../../../../../models/signage/useFloor/type"
import { MeetingRoomType } from "../../../../../models/signage/useFloor/type"
import {
  meetingRoomStyle,
  meetingRoomIconStyle,
} from "../../../../../styles/meetingStyle"
import {
  reservedEmployeesStyle,
  checkInReservedEmployeesStyle,
} from "../../../../../styles/seatStyle"
import { formatTime } from "../../../../../utils/time"

interface Props {
  meetingRoom: MeetingRoomType
}

// 会議室オブジェクト
export const SignageFloorMeetingRoom = (props: Props) => {
  const {
    id,
    meeting_room_code,
    start_x,
    start_y,
    end_x,
    end_y,
    schedules,
    color_code,
  } = props.meetingRoom

  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)

  const onMouseEnter = () => {
    setTooltipOpen(true)
  }

  const onMouseLeave = () => {
    setTooltipOpen(false)
  }

  const timeFormat = (time: string) => {
    const isoTime = time.replace(" ", "T").replace(" ", "")
    const date = new Date(isoTime)
    const hour = ("0" + date.getHours()).slice(-2)
    const min = ("0" + date.getMinutes()).slice(-2)

    return `${hour}:${min}`
  }
  const tooltipText = (schedules: Schedule[]) => {
    const schedule = schedules[0]
    let employeesName = ""
    schedules.map((schedule) => {
      switch (schedule.userable_type) {
        case "Employee":
          employeesName += `${schedule?.userable?.last_name ?? ""} ${
            schedule?.userable?.first_name ?? ""
          }, `
          break
        case "Guest":
          employeesName += `${schedule?.userable?.last_name ?? ""} ${
            schedule?.userable?.first_name ?? ""
          }(ゲスト), `
      }
    })
    const scheduleTitle = schedule?.schedule_title
    const scheduleTime = schedule?.whole_day_flag
      ? "終日"
      : `${timeFormat(schedule?.start_time as string)} ~ ${timeFormat(
          schedule?.end_time as string
        )}`

    const seatInfo = meeting_room_code
    return `会議室番号: ${seatInfo}\n予約者: ${employeesName}\n件名: ${scheduleTitle}\n${scheduleTime}`
  }

  return (
    <>
      <Box
        id={`M${String(id)}`}
        className={"meetingRoom"}
        sx={meetingRoomStyle(start_x, start_y, end_x, end_y, color_code)}
        // TODO: この辺のイベントがどう動くか実機検証必要
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></div>
        <span style={meetingRoomIconStyle(color_code) as React.CSSProperties}>
          M
        </span>
        <span
          style={{
            top: "60px",
            left: "60px",
            color: color_code,
            position: "absolute",
            fontSize: "30px",
          }}
        >
          {meeting_room_code}
        </span>
        {schedules && (
          <Tooltip
            title={schedules.length > 0 ? tooltipText(schedules) : ""}
            placement="bottom"
            disableInteractive
            open={schedules.length > 0 && tooltipOpen}
            componentsProps={{
              tooltip: {
                sx: {
                  whiteSpace: "pre-wrap",
                  position: "fixed",
                  width: "200px",
                },
              },
            }}
          >
            <Grid
              container
              style={{
                bottom: "10px",
                left: "40px",
                position: "absolute",
                display: "flex",
                alignItems: "center",
              }}
            >
              {schedules.map((schedule, index) => {
                return (
                  <Grid item key={index} sm={4}>
                    <Box
                      sx={
                        schedule.checked_in
                          ? (checkInReservedEmployeesStyle as React.CSSProperties)
                          : (reservedEmployeesStyle as React.CSSProperties)
                      }
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {schedule.image_blob ? (
                        <img
                          src={schedule.image_blob}
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                          }}
                        />
                      ) : (
                        <Typography
                          sx={{ fontSize: "20px" }}
                          color="inherit"
                          display="flex"
                        >
                          <span>{schedule.userable?.last_name?.[0] ?? ""}</span>
                          <span>
                            {schedule.userable?.first_name?.[0] ?? ""}
                          </span>
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
          </Tooltip>
        )}
      </Box>
    </>
  )
}
