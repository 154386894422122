import { yupResolver } from "@hookform/resolvers/yup"
import { getDate, getDay } from "date-fns"
import * as Yup from "yup"

import { useCallback, useEffect, useState } from "react"
import { SubmitHandler, useForm, UseFormReturn } from "react-hook-form"
import { useNavigate, useLocation } from "react-router-dom"

import {
  createGoogleEventRequest,
  updateGoogleEventRequest,
  updateMicrosoftEventRequest,
} from "../../../../api/employee/calendarGoogleRequest"
import { createOutlookEventRequest } from "../../../../api/employee/calendarRequest"
import {
  editScheduleRequest,
  newScheduleRequest,
} from "../../../../api/employee/scheduleRequest"
import { useLanguage } from "../../../../contexts/LanguageContext"
import enTranslations from "../../../../translations/errorMessage/en"
import jaTranslations from "../../../../translations/errorMessage/ja"
import { MonthlyRecurringType, ScheduleInformation } from "../show/type"
import { EmployeesType } from "../type"
import {
  EveryDateType,
  ScheduleForm,
  ScheduleFormLocationType,
  WhoIsWheres,
} from "./type"

const roundDownMinutesDate = (date: Date): Date => {
  const roundDownMinute = 60 // 何分単位で繰り上げ・繰り下げするか
  const roundDownNumber = 0 // 0にすると繰り下げ・1にすると繰り上げ
  const roundedDownMinutes =
    ((date.getMinutes() / roundDownMinute) | roundDownNumber) * roundDownMinute

  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    roundedDownMinutes
  )
}

export const useScheduleFormNavigation = () => {
  // 言語切り替え
  const locationState = useLocation().state as ScheduleFormLocationType
  if (locationState) {
    const navigateFrom =
      locationState.navigateFrom === undefined
        ? "/employee/calendar/days"
        : locationState.navigateFrom
    return navigateFrom
  } else {
    return "/mobile/home"
  }
}

const useScheduleForm = (
  employees: EmployeesType | undefined,
  scheduleForm: ScheduleForm | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): UseFormReturn<ScheduleForm, any> => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const scheduleFormValidation = Yup.object({
    schedule: Yup.object({
      schedule_title: Yup.string().nullable(),
      message_content: Yup.string(),
      whole_day_flag: Yup.boolean(),
      start_date: Yup.date().typeError("開始日を指定してください"),
      start_time: Yup.date().when("whole_day_flag", {
        is: true,
        then: Yup.date().strip(),
        otherwise: Yup.date().typeError("開始時刻を指定してください"),
      }),
      end_date: Yup.date(),
      end_time: Yup.date().when("whole_day_flag", {
        is: true,
        then: Yup.date().strip(),
        otherwise: Yup.date().typeError("開始時刻を指定してください"),
      }),
      schedule_date_type: Yup.number().min(0).max(1),
      weekly_or_monthly: Yup.string().when("schedule_date_type", {
        is: 1,
        then: Yup.string().oneOf(["weekly", "monthly"]),
        otherwise: Yup.string().strip(),
      }),
      is_secret: Yup.number().min(0).max(1),
      weekly: Yup.object().when(["schedule_date_type", "weekly_or_monthly"], {
        is: (schedule_date_type: number, weekly_or_monthly: string) =>
          schedule_date_type === 1 && weekly_or_monthly === "weekly",
        then: Yup.object().shape({
          every_week: Yup.string()
            .required(translations.Required)
            .matches(/\d+/g, "数字入力になります"),
          days: Yup.array()
            .of(Yup.boolean())
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .test("days", "最低1つは選択してください", (value: any) => {
              return value?.includes(true)
            }),
        }),
        otherwise: Yup.object().strip(),
      }),
      monthly: Yup.object().when(["schedule_date_type", "weekly_or_monthly"], {
        is: (schedule_date_type: number, weekly_or_monthly: string) =>
          schedule_date_type === 1 && weekly_or_monthly === "monthly",
        then: Yup.object().shape({
          dayly: Yup.object({
            monthly: Yup.number(),
            day: Yup.number(),
          }),
          dayOfWeekly: Yup.object({
            monthly: Yup.number(),
            weekly: Yup.number(),
            dayOfWeek: Yup.number(),
          }),
        }),
        otherwise: Yup.object().strip(),
      }),
    }),
  })

  const startTime = roundDownMinutesDate(new Date())
  const endTime = roundDownMinutesDate(
    new Date(
      startTime.getFullYear(),
      startTime.getMonth(),
      startTime.getDate(),
      startTime.getHours() + 1
    )
  )
  const getThisWeek = () => {
    const today = new Date()
    return Math.floor((today.getDate() - today.getDay() + 12) / 7)
  }

  return useForm<ScheduleForm>({
    mode: "onBlur",
    defaultValues: scheduleForm
      ? scheduleForm
      : {
          schedule: {
            id: 0,
            schedule_title: "",
            message_content: "",
            start_date: new Date(),
            start_time: startTime,
            end_date: new Date(),
            end_time: endTime,
            whole_day_flag: false,
            schedule_date_type: 0,
            weekly_or_monthly: "weekly",
            is_secret: 0,
            weekly: {
              every_week: 1,
              days: new Array(7).fill(false),
            },
            monthly: {
              dayly: {
                monthly: 1,
                day: getDate(new Date()),
              },
              dayOfWeekly: {
                monthly: 1,
                weekly: getThisWeek(),
                dayOfWeek: getDay(new Date()),
              },
            },
            share_schedule: {
              who_is_wheres: employees
                ? employees.map((employee) => {
                    return {
                      userable: {
                        userable_type: "Employee",
                        userable_id: employee.id,
                        last_name: employee.last_name,
                        first_name: employee.first_name,
                        email: employee.email,
                      },
                      reservable: {
                        reservable_type: "Seat",
                        reservable_id: undefined,
                        name: "",
                      },
                    }
                  })
                : [],
              notification: "manual",
            },
            is_email: false,
            is_confirm: false,
          },
        },
    resolver: yupResolver(scheduleFormValidation),
  })
}

export const useNewScheduleForm = (
  employees?: EmployeesType,
  scheduleForm?: ScheduleForm
) => {
  const navigate = useNavigate()
  const navigateFrom = useScheduleFormNavigation()
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [validEndDateTime, setValidEndDateTime] = useState<string>(
    "rgba(0, 0, 0, 0.23)"
  )
  const [validEndDate, setValidEndDate] = useState<string>(
    "rgba(0, 0, 0, 0.23)"
  )

  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const methods = useScheduleForm(employees, scheduleForm)
  const startDateParam = methods.watch("schedule.start_date")
  const endDateParam = methods.watch("schedule.end_date")
  const startTimeParam = methods.watch("schedule.start_time")
  const endTimeParam = methods.watch("schedule.end_time")
  const wholeDayFlagParam = methods.watch("schedule.whole_day_flag")
  const whoIsWhereParam = methods.watch("schedule.share_schedule.who_is_wheres")

  const checkMeetingOrSeat = (whoIsWheres: WhoIsWheres) => {
    const reservableMeetingOrSeat = whoIsWheres.filter((whoIsWhere) => {
      return whoIsWhere.reservable.reservable_id
    })

    return reservableMeetingOrSeat.length !== 0
  }

  const onNewScheduleSubmit: SubmitHandler<ScheduleForm> = useCallback(
    async (params: ScheduleForm) => {
      const { data, error } = await newScheduleRequest(params)
      if (!error) {
        if (
          data?.outlook_authenticated &&
          (params.schedule.share_schedule.who_is_wheres[0].reservable
            .reservable_type != "Seat" ||
            params.schedule.share_schedule.who_is_wheres[0].reservable
              .reservable_id === undefined)
        ) {
          const { error } = await createOutlookEventRequest({
            ...params,
            schedule_information_id: data.schedule_information_id,
          })
          if (error) {
            setErrorMessages([
              "予定表の登録に失敗しました。",
              "入力に誤りがないかご確認ください。",
            ])
            return
          }
        } else if (
          data?.google_authenticated &&
          (params.schedule.share_schedule.who_is_wheres[0].reservable
            .reservable_type != "Seat" ||
            params.schedule.share_schedule.who_is_wheres[0].reservable
              .reservable_id === undefined)
        ) {
          const { error } = await createGoogleEventRequest({
            ...params,
            schedule_information_id: data.schedule_information_id,
          })
          if (error) {
            setErrorMessages([
              "予定表の登録に失敗しました。",
              "入力に誤りがないかご確認ください。",
            ])
            return
          }
        }
        localStorage.setItem("alertContent", translations.YourScheduleAdded)
        if (navigateFrom === "/mobile/home") {
          window.location.reload()
        } else {
          navigate(navigateFrom, {
            state: {
              navigateFrom: window.location.pathname,
              scheduleDate: {
                scheduleStartDate: params.schedule.start_date,
              },
            },
          })
        }
      } else if (error.length != 0) {
        setErrorMessages(error)
      } else {
        setErrorMessages([
          "予定表の登録に失敗しました。",
          "入力に誤りがないかご確認ください。",
        ])
      }
    },
    []
  )

  useEffect(() => {
    if (
      startDateParam !== null &&
      endDateParam !== null &&
      startTimeParam !== null &&
      endTimeParam !== null
    ) {
      const dateTimeValidation = () => {
        const startDateTime = new Date(
          startDateParam.getFullYear(),
          startDateParam.getMonth() + 1,
          startDateParam.getDate(),
          startTimeParam.getHours(),
          startTimeParam.getMinutes()
        )
        const endDateTime = new Date(
          endDateParam.getFullYear(),
          endDateParam.getMonth() + 1,
          endDateParam.getDate(),
          endTimeParam.getHours(),
          endTimeParam.getMinutes()
        )

        return startDateTime.getTime() < endDateTime.getTime()
      }
      const dateValidation = () => {
        const startDate = new Date(
          startDateParam.getFullYear(),
          startDateParam.getMonth() + 1,
          startDateParam.getDate()
        )
        const endDate = new Date(
          endDateParam.getFullYear(),
          endDateParam.getMonth() + 1,
          endDateParam.getDate()
        )

        return startDate.getTime() === endDate.getTime()
      }

      const wholeDayDateValidation = () => {
        const startDate = new Date(
          startDateParam.getFullYear(),
          startDateParam.getMonth() + 1,
          startDateParam.getDate()
        )
        const endDate = new Date(
          endDateParam.getFullYear(),
          endDateParam.getMonth() + 1,
          endDateParam.getDate()
        )

        return startDate.getTime() <= endDate.getTime()
      }

      if (wholeDayFlagParam) {
        // 終日の場合は日付だけで判定する
        if (wholeDayDateValidation()) {
          setErrorMessages([])
          setValidEndDateTime("rgba(0, 0, 0, 0.23)")
          setValidEndDate("rgba(0, 0, 0, 0.23)")
        } else {
          setErrorMessages([translations.Incorrect])
          setValidEndDate("#d32f2f")
          setValidEndDateTime("rgba(0, 0, 0, 0.23)")
        }
      } else {
        // 日時が間違っている = 日付もしくは時刻が間違っている
        if (!dateTimeValidation()) {
          setErrorMessages([translations.Incorrect])
          // 日付があっている場合時刻が違うので時刻のinputを赤くする
          if (dateValidation()) {
            setValidEndDateTime("#d32f2f")
            setValidEndDate("rgba(0, 0, 0, 0.23)")
          } else {
            // そうではない場合日付が間違っているので日付のinputを赤くする
            setValidEndDate("#d32f2f")
            setValidEndDateTime("rgba(0, 0, 0, 0.23)")
          }
        } else {
          // 日時があっている場合エラーを出さない
          setErrorMessages([])
          setValidEndDate("rgba(0, 0, 0, 0.23)")
          setValidEndDateTime("rgba(0, 0, 0, 0.23)")
        }
      }
    }
  }, [
    startDateParam,
    endDateParam,
    startTimeParam,
    endTimeParam,
    wholeDayFlagParam,
    checkMeetingOrSeat(whoIsWhereParam),
  ])

  return {
    methods,
    errorMessages,
    onNewScheduleSubmit,
    setErrorMessages,
    validEndDateTime,
    validEndDate,
    setValidEndDateTime,
    setValidEndDate,
  }
}

const daylyObject = (
  monthly_recurring_type: MonthlyRecurringType,
  startDate: Date
) => {
  if (monthly_recurring_type?.daily_or_week_day === "daily") {
    return {
      monthly: monthly_recurring_type.every_monthly,
      day: monthly_recurring_type.day,
    }
  } else {
    return {
      monthly: 1,
      day: getDate(startDate),
    }
  }
}

const getWeek = (newStartTime: Date) => {
  return Math.floor((newStartTime.getDate() - newStartTime.getDay() + 12) / 7)
}

const dayOfWeeklyObject = (
  monthly_recurring_type: MonthlyRecurringType,
  startDate: Date
) => {
  if (monthly_recurring_type?.daily_or_week_day === "week_day") {
    return {
      monthly: monthly_recurring_type.every_monthly,
      weekly: monthly_recurring_type.number_day_of_week,
      dayOfWeek: monthly_recurring_type.day_of_week,
    }
  } else {
    return {
      monthly: 1,
      weekly: getWeek(startDate),
      dayOfWeek: getDay(startDate),
    }
  }
}

const weekly_recurring_type = (
  scheduleInformation: ScheduleInformation
): EveryDateType => {
  if (scheduleInformation.weekly_recurring_type) {
    return "weekly"
  } else if (scheduleInformation?.monthly_recurring_type) {
    return "monthly"
  }
  return "weekly"
}

export const useEditSchedule = (
  scheduleInformation: ScheduleInformation | { scheduleForm: ScheduleForm }
) => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const scheduleFormValidation = Yup.object({
    schedule: Yup.object({
      schedule_title: Yup.string().nullable(),
      message_content: Yup.string(),
      whole_day_flag: Yup.boolean(),
      start_date: Yup.date().typeError("開始日を指定してください"),
      start_time: Yup.date().when("whole_day_flag", {
        is: true,
        then: Yup.date().strip(),
        otherwise: Yup.date().typeError("開始時刻を指定してください"),
      }),
      end_date: Yup.date(),
      end_time: Yup.date().when("whole_day_flag", {
        is: true,
        then: Yup.date().strip(),
        otherwise: Yup.date().typeError("開始時刻を指定してください"),
      }),
      schedule_date_type: Yup.number().min(0).max(1),
      weekly_or_monthly: Yup.string().when("schedule_date_type", {
        is: 1,
        then: Yup.string().oneOf(["weekly", "monthly"]),
        otherwise: Yup.string().strip(),
      }),
      is_secret: Yup.number().min(0).max(1),
      weekly: Yup.object().when(["schedule_date_type", "weekly_or_monthly"], {
        is: (schedule_date_type: number, weekly_or_monthly: string) =>
          schedule_date_type === 1 && weekly_or_monthly === "weekly",
        then: Yup.object().shape({
          every_week: Yup.string()
            .required(translations.Required)
            .matches(/\d+/g, "数字入力になります"),
          days: Yup.array()
            .of(Yup.boolean())
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .test("days", "最低1つは選択してください", (value: any) => {
              return value?.includes(true)
            }),
        }),
        otherwise: Yup.object().strip(),
      }),
      monthly: Yup.object().when(["schedule_date_type", "weekly_or_monthly"], {
        is: (schedule_date_type: number, weekly_or_monthly: string) =>
          schedule_date_type === 1 && weekly_or_monthly === "monthly",
        then: Yup.object().shape({
          dayly: Yup.object({
            monthly: Yup.number(),
            day: Yup.number(),
          }),
          dayOfWeekly: Yup.object({
            monthly: Yup.number(),
            weekly: Yup.number(),
            dayOfWeek: Yup.number(),
          }),
        }),
        otherwise: Yup.object().strip(),
      }),
    }),
  })

  const navigate = useNavigate()
  const navigateFrom = useScheduleFormNavigation()
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [validEndDateTime, setValidEndDateTime] = useState<string>(
    "rgba(0, 0, 0, 0.23)"
  )
  const [validEndDate, setValidEndDate] = useState<string>(
    "rgba(0, 0, 0, 0.23)"
  )

  const methods = useForm<ScheduleForm>({
    mode: "onBlur",
    defaultValues:
      "schedules" in scheduleInformation
        ? {
            schedule: {
              id: scheduleInformation.id,
              schedule_title: scheduleInformation.schedule_title,
              message_content: scheduleInformation.message_content,
              whole_day_flag: scheduleInformation.whole_day_flag,
              schedule_date_type:
                scheduleInformation.schedule_date_type === "single" ? 0 : 1,
              start_date: new Date(scheduleInformation.start_date),
              end_date: new Date(scheduleInformation.end_date),
              start_time: new Date(scheduleInformation.start_time),
              end_time: new Date(scheduleInformation.end_time),
              weekly_or_monthly: weekly_recurring_type(scheduleInformation),
              is_secret:
                scheduleInformation.schedules[0].is_secret === true ? 1 : 0,
              weekly: {
                every_week: scheduleInformation.weekly_recurring_type
                  ? scheduleInformation.weekly_recurring_type.every_week
                  : 1,
                days: scheduleInformation.weekly_recurring_type
                  ? [
                      scheduleInformation.weekly_recurring_type.sunday,
                      scheduleInformation.weekly_recurring_type.monday,
                      scheduleInformation.weekly_recurring_type.tuesday,
                      scheduleInformation.weekly_recurring_type.wednesday,
                      scheduleInformation.weekly_recurring_type.thursday,
                      scheduleInformation.weekly_recurring_type.friday,
                      scheduleInformation.weekly_recurring_type.saturday,
                    ]
                  : new Array<boolean>(7).fill(false),
              },
              monthly: {
                dayly: daylyObject(
                  scheduleInformation?.monthly_recurring_type,
                  new Date(scheduleInformation.start_date)
                ),
                dayOfWeekly: dayOfWeeklyObject(
                  scheduleInformation?.monthly_recurring_type,
                  new Date(scheduleInformation.start_date)
                ),
              },
              share_schedule: {
                who_is_wheres: scheduleInformation.schedules.map((schedule) => {
                  return {
                    userable: {
                      userable_type: schedule.userable?.userable_type ?? "",
                      userable_id: schedule.userable?.id ?? 0,
                      last_name: schedule.userable?.last_name ?? "",
                      first_name: schedule.userable?.first_name ?? "",
                      email: schedule.userable?.email ?? "",
                      company_name: schedule.userable?.company_name ?? "",
                      should_send_guest_reception_notification_mail:
                        schedule.should_send_guest_reception_notification_mail,
                    },
                    reservable: {
                      reservable_type: schedule.reservable?.reservable_type,
                      reservable_id: schedule.reservable?.id,
                      name: schedule.reservable?.name,
                    },
                  }
                }),
                notification: "manual",
              },
              is_email: false,
              is_confirm: false,
            },
          }
        : scheduleInformation.scheduleForm,
    resolver: yupResolver(scheduleFormValidation),
  })

  const startDateParam = methods.watch("schedule.start_date")
  const endDateParam = methods.watch("schedule.end_date")
  const startTimeParam = methods.watch("schedule.start_time")
  const endTimeParam = methods.watch("schedule.end_time")
  const wholeDayFlagParam = methods.watch("schedule.whole_day_flag")
  const whoIsWhereParam = methods.watch("schedule.share_schedule.who_is_wheres")

  const checkMeetingOrSeat = (whoIsWheres: WhoIsWheres) => {
    const reservableMeetingOrSeat = whoIsWheres.filter((whoIsWhere) => {
      return whoIsWhere.reservable.reservable_id
    })

    return reservableMeetingOrSeat.length !== 0
  }

  const onEditScheduleSubmit: SubmitHandler<ScheduleForm> = useCallback(
    async (params: ScheduleForm) => {
      const { data, error } = await editScheduleRequest(params)
      if (!error) {
        if (data?.google_authenticated) {
          const { error } = await updateGoogleEventRequest({
            ...params,
            // 更新前のschedule_information_id
            schedule_id: data.schedule_id,
            // 更新後のschedule_information_id
            schedule_information_id: data.schedule_information_id,
            // 退避したイベントID
            google_event_id: data.google_event_id,
          })
          if (error) {
            setErrorMessages([
              "予定表の更新に失敗しました。",
              "入力に誤りがないかご確認ください。",
            ])
            return
          }
        } else if (data?.outlook_authenticated) {
          const { error } = await updateMicrosoftEventRequest({
            ...params,
            schedule_information_id: data.schedule_information_id,
            outlook_event_id: data.outlook_event_id,
          })
          if (error) {
            setErrorMessages([
              "予定表の更新に失敗しました。",
              "入力に誤りがないかご確認ください。",
            ])
            return
          }
        }
        localStorage.setItem("alertContent", translations.ScheduleUpdated)
        if (navigateFrom === "/mobile/home") {
          window.location.reload()
        } else {
          navigate(navigateFrom, {
            state: {
              navigateFrom: window.location.pathname,
              scheduleDate: {
                scheduleStartDate: params.schedule.start_date,
              },
            },
          })
        }
      } else if (error.length != 0) {
        setErrorMessages(error)
      } else {
        setErrorMessages([
          "予定表の更新に失敗しました。",
          "入力に誤りがないかご確認ください。",
        ])
      }
    },
    []
  )

  useEffect(() => {
    if (
      startDateParam !== null &&
      endDateParam !== null &&
      startTimeParam !== null &&
      endTimeParam !== null
    ) {
      const dateTimeValidation = () => {
        const startDateTime = new Date(
          startDateParam.getFullYear(),
          startDateParam.getMonth() + 1,
          startDateParam.getDate(),
          startTimeParam.getHours(),
          startTimeParam.getMinutes()
        )
        const endDateTime = new Date(
          endDateParam.getFullYear(),
          endDateParam.getMonth() + 1,
          endDateParam.getDate(),
          endTimeParam.getHours(),
          endTimeParam.getMinutes()
        )

        return startDateTime.getTime() < endDateTime.getTime()
      }

      const dateValidation = () => {
        const startDate = new Date(
          startDateParam.getFullYear(),
          startDateParam.getMonth() + 1,
          startDateParam.getDate()
        )
        const endDate = new Date(
          endDateParam.getFullYear(),
          endDateParam.getMonth() + 1,
          endDateParam.getDate()
        )

        return startDate.getTime() === endDate.getTime()
      }

      const wholeDayDateValidation = () => {
        const startDate = new Date(
          startDateParam.getFullYear(),
          startDateParam.getMonth() + 1,
          startDateParam.getDate()
        )
        const endDate = new Date(
          endDateParam.getFullYear(),
          endDateParam.getMonth() + 1,
          endDateParam.getDate()
        )

        return startDate.getTime() <= endDate.getTime()
      }

      if (wholeDayFlagParam) {
        if (wholeDayDateValidation()) {
          setErrorMessages([])
          setValidEndDateTime("rgba(0, 0, 0, 0.23)")
          setValidEndDate("rgba(0, 0, 0, 0.23)")
        } else {
          setErrorMessages([translations.Incorrect])
          setValidEndDate("#d32f2f")
          setValidEndDateTime("rgba(0, 0, 0, 0.23)")
        }
      } else {
        // 日時が間違っている = 日付もしくは時刻が間違っている
        if (!dateTimeValidation()) {
          setErrorMessages([translations.Incorrect])
          // 日付があっている場合時刻が違うので時刻のinputを赤くする
          if (dateValidation()) {
            setValidEndDateTime("#d32f2f")
            setValidEndDate("rgba(0, 0, 0, 0.23)")
          } else {
            // そうではない場合日付が間違っているので日付のinputを赤くする
            setValidEndDate("#d32f2f")
            setValidEndDateTime("rgba(0, 0, 0, 0.23)")
          }
        } else {
          // 日時があっている場合エラーを出さない
          setErrorMessages([])
          setValidEndDate("rgba(0, 0, 0, 0.23)")
          setValidEndDateTime("rgba(0, 0, 0, 0.23)")
        }
      }
    }
  }, [
    startDateParam,
    endDateParam,
    startTimeParam,
    endTimeParam,
    wholeDayFlagParam,
    checkMeetingOrSeat(whoIsWhereParam),
  ])

  return {
    methods,
    errorMessages,
    onEditScheduleSubmit,
    setErrorMessages,
    validEndDateTime,
    validEndDate,
    setValidEndDateTime,
    setValidEndDate,
  }
}
