import React, { memo, useEffect, useState } from "react"
import { SketchPicker } from "react-color"
import { UseFormReturn, Controller, SubmitHandler } from "react-hook-form"

import FileUploadIcon from "@mui/icons-material/FileUpload"
import {
  Grid,
  styled,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Switch,
  InputLabel,
  OutlinedInput,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
  Box,
  Button,
  Chip,
  FormControlLabel,
  Modal,
  Tab,
  Tabs,
} from "@mui/material"

import {
  SPECIFIC,
  ALL,
  SidebarLayoutComponent,
  SidebarTabType,
} from "../../../../models/company/useLayout"
import {
  AreaFormType,
  AreaType,
  SeatType,
} from "../../../../models/company/useLayout/type"
import { useAutoCancelTimes } from "../../../../models/public/useAutoCancelTimes"
import { CompanyRelationsType } from "../../../../models/public/useCompanyRelations/type"
import { TagCategoryType, TagType } from "../../../../models/public/useTag/type"
import { useImageUpload } from "../../../../utils/imageUpload"
import { handleTeamNameChange } from "../../../../utils/teamIDtoTeamName"
import { GreenArrowIcon } from "../../../public/GreenArrowIcon"
import { ReflectButton } from "../../../public/ReflectButton"

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-input": {
    padding: "5px",
  },
})

const CustomBox = styled(Box)({
  display: "flex",
  alignItems: "center",
})

const CustomLayoutModal = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: "70%",
}))

interface Props {
  companyRelations: CompanyRelationsType
  area: AreaType
  areaToSeats: SeatType[]
  areaForm: UseFormReturn<AreaFormType>
  handleCreateArea: SubmitHandler<AreaFormType>
  handleUpdateArea: SubmitHandler<AreaFormType>
  tagCategories: TagCategoryType[]
  setSidebarLayoutComponent: React.Dispatch<
    React.SetStateAction<SidebarLayoutComponent>
  >
  setSidebarTabType: React.Dispatch<React.SetStateAction<SidebarTabType>>
  resetAreaSeats: () => void
}

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 225,
      width: 250,
    },
  },
}

const MenuSelectProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
}

export const CompanyLayoutAreaForm: React.FC<Props> = memo(
  ({
    companyRelations,
    area,
    areaToSeats,
    areaForm,
    handleCreateArea,
    handleUpdateArea,
    tagCategories,
    setSidebarLayoutComponent,
    setSidebarTabType,
    resetAreaSeats,
  }: Props) => {
    const {
      control,
      setValue,
      handleSubmit,
      reset,
      formState: { errors, isValid },
    } = areaForm

    const [reservableTeamIds, setReservableTeamIds] = useState<number[]>(
      area.reservable_team_ids
    )
    const [reservableTeamNames, setReservableTeamNames] = useState<string[]>([])
    const [availabilityTeamIds, setAvailabilityTeamIds] = useState<number[]>(
      area.availability_team_ids
    )
    const [availabilityTeamNames, setAvailabilityTeamNames] = useState<
      string[]
    >([])
    const [isReservable, setIsReservable] = useState<boolean>(false)
    const [outsideTeamUsable, setOutsideTeamUsable] = useState<boolean>(false)
    const [isAreaToMeetingRoom, setIsAreaToMeetingRoom] =
      useState<boolean>(false)
    const [colorCode, setColorCode] = useState<string>(area.color_code)
    const [openColor, setOpenColor] = useState<boolean>(false)
    const [tags, setTags] = useState<TagType[]>([])
    const [tagCategoryId, setTagCategoryId] = React.useState<number>(0)
    const [selectdTags, setSelectdTags] = useState<TagType[]>([])
    const [isOpen, setIsOpen] = useState(false)
    const open = () => setIsOpen(true)
    const close = () => setIsOpen(false)
    const { imgBlob, imgFile, getRootProps, getInputProps, setImgFile } =
      useImageUpload()
    const { autoCancelTimes, fetchAutoCancelTimes } = useAutoCancelTimes()
    const [autoCancelTimeMinutes, setAutoCancelTimeMinutes] = useState<number>(
      area.auto_cancel_times_id
    )

    // 画像を挿入した場合、image_blobを更新する
    useEffect(() => {
      setValue("image_blob", imgBlob)
      setImgFile(imgFile)
    }, [imgBlob])

    // カラーコードの開閉
    const handleColorOpen = () => {
      setOpenColor(!openColor)
    }

    // 所属選択をしたときに所属IDと所属名の値を設定する
    // useStateとsetValueを両方使っている理由： 表示部分と登録ボタンを押したときに値が保持されないため
    const handleTeamChange = (
      e: SelectChangeEvent<typeof reservableTeamIds>
    ) => {
      const teamIds = e.target.value
      const targetTeamIds =
        typeof teamIds === "string" ? teamIds.split(",").map(Number) : teamIds

      // 全選択された場合
      if (targetTeamIds.includes(0)) {
        switch (e.target.name) {
          case "reservable_team_ids":
            // すでに全選択がされている時に全選択をクリックした時
            if (reservableTeamIds.length === companyRelations.teams.length) {
              // 所属IDと所属名を空配列にする
              setReservableTeamNames([])
              setReservableTeamIds([])
              setValue("reservable_type", SPECIFIC)
              setValue("reservable_team_ids", [])
            } else {
              // 全ての所属IDと所属名を設定する
              setReservableTeamNames(
                companyRelations.teams.map((team) => team.team_name)
              )
              setReservableTeamIds(
                companyRelations.teams.map((team) => team.id)
              )
              setValue("reservable_type", ALL)
              setValue(
                "reservable_team_ids",
                companyRelations.teams.map((team) => team.id)
              )
            }
            break
          case "availability_team_ids":
            // すでに全選択がされている時に全選択をクリックした時
            if (availabilityTeamIds.length === companyRelations.teams.length) {
              // 所属IDと所属名を空配列にする
              setAvailabilityTeamNames([])
              setAvailabilityTeamIds([])
              setValue("availability_type", SPECIFIC)
              setValue("availability_team_ids", [])
            } else {
              // 全ての所属IDと所属名を設定する
              setAvailabilityTeamNames(
                companyRelations.teams.map((team) => team.team_name)
              )
              setAvailabilityTeamIds(
                companyRelations.teams.map((team) => team.id)
              )
              setValue("availability_type", SPECIFIC)
              setValue(
                "availability_team_ids",
                companyRelations.teams.map((team) => team.id)
              )
            }
            break
        }
      } else {
        // 所属IDに紐づく所属名を取得する
        const targetTeamNames = handleTeamNameChange(
          targetTeamIds,
          companyRelations.teams
        )

        switch (e.target.name) {
          case "reservable_team_ids":
            setValue("reservable_type", SPECIFIC)
            setValue("reservable_team_ids", targetTeamIds)
            setReservableTeamNames(targetTeamNames)

            return setReservableTeamIds(targetTeamIds)
          case "availability_team_ids":
            setValue("availability_type", SPECIFIC)
            setValue("availability_team_ids", targetTeamIds)
            setAvailabilityTeamNames(targetTeamNames)

            return setAvailabilityTeamIds(targetTeamIds)
        }
      }
    }

    const handleReservableTypeChange = () => {
      setValue("is_reservable", !isReservable)
      setIsReservable(!isReservable)
    }

    const handleAreaToMeetingRoomChange = () => {
      setValue("is_area_to_meeting_room", !isAreaToMeetingRoom)
      setIsAreaToMeetingRoom(!isAreaToMeetingRoom)
    }
    const handleOutsideTeamUsableChange = () => {
      setValue("outside_team_usable", !outsideTeamUsable)
      setOutsideTeamUsable(!outsideTeamUsable)
    }

    const freeSeatSearch = () => {
      return areaToSeats.filter((targetSeat) => {
        return targetSeat.seat_type === 1
      })
    }

    /*
      タグに関連するイベント
    */
    const handleTagCategoryChange = (
      e: React.SyntheticEvent,
      newValue: number
    ) => {
      const tagCategory = tagCategories.find((tagCategory) => {
        return tagCategory.id === newValue
      })
      if (tagCategory) {
        setTags(tagCategory.tags)
      }
      setTagCategoryId(newValue)
    }

    const selectedTagCheck = (tagId: number) => {
      const resultTag = selectdTags.find((selectdTag) => {
        return selectdTag.id === tagId
      })

      return resultTag ? true : false
    }

    const handleTagCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
      // タグが3つ以上選ばれた場合、処理を中止する
      if (selectdTags.length >= 3 && e.target.checked) {
        return
      }

      const tagCategory = tagCategories.find((tagCategory) => {
        return tagCategory.id === tagCategoryId
      })

      if (tagCategory) {
        const targetTag = tagCategory.tags.find((tag) => {
          return tag.id === Number(e.target.value)
        })

        if (e.target.checked && targetTag) {
          const resultTagIds = selectdTags.map((resultTag) => {
            return resultTag.id
          })
          setValue("tag_ids", [...resultTagIds, Number(e.target.value)])
          setSelectdTags([...selectdTags, targetTag])
        } else {
          const resultTagIds = selectdTags.filter((selectdTag) => {
            return selectdTag.id !== Number(e.target.value)
          })
          setValue(
            "tag_ids",
            resultTagIds.map((resultTag) => {
              return resultTag.id
            })
          )
          setSelectdTags(resultTagIds)
        }
      }
    }

    useEffect(() => {
      setReservableTeamNames(
        handleTeamNameChange(area.reservable_team_ids, companyRelations.teams)
      )
      setAvailabilityTeamNames(
        handleTeamNameChange(area.availability_team_ids, companyRelations.teams)
      )
      // areaのidが0以外の場合、既に追加しているエリア情報を反映させる
      if (area.id && area.id !== 0) {
        const outsideTeamUsable = area.area_to_meeting_room?.outside_team_usable
          ? area.area_to_meeting_room?.outside_team_usable
          : false
        reset({
          id: area.id,
          area_name: area.area_name,
          is_reservable: area.is_reservable,
          is_area_to_meeting_room: area.is_area_to_meeting_room,
          availability_type: area.availability_type,
          reservable_type: area.reservable_type,
          availability_team_ids: area.availability_team_ids,
          reservable_team_ids: area.reservable_team_ids,
          area_description: area.area_description,
          available_time: area.area_to_meeting_room?.available_time
            ? area.area_to_meeting_room?.available_time
            : 0,
          color_code: area.color_code,
          outside_team_usable: outsideTeamUsable,
          tag_ids: area.tag_ids,
          image_blob: area.image_blob,
          auto_cancel_times_id: area.auto_cancel_times_id,
          area_reservable_all: area.area_reservable_all,
          area_availability_all: area.area_availability_all,
        })

        if (area.image_blob) {
          const createObjectURL = (window.URL || window.webkitURL)
            .createObjectURL
          setImgFile(createObjectURL(area.image_blob as Blob | MediaSource))
          setValue("image_blob", area.image_blob)
        }

        setSelectdTags(
          tagCategories
            .map((tagCategory) => {
              return tagCategory.tags
                .map((tag) => {
                  if (area.tag_ids.includes(tag.id)) {
                    return tag
                  }
                })
                .flatMap((e) => e ?? [])
            })
            .flatMap((e) => e ?? [])
        )

        setIsReservable(area.is_reservable)
        setIsAreaToMeetingRoom(area.is_area_to_meeting_room)
        setOutsideTeamUsable(outsideTeamUsable)
      }
    }, [])
    const handleSetAutoCancelTimeId = (e: SelectChangeEvent<number>) => {
      setValue("auto_cancel_times_id", Number(e.target.value))
      setAutoCancelTimeMinutes(Number(e.target.value))
    }

    useEffect(() => {
      fetchAutoCancelTimes()
    }, [])

    const handleAreaReservableAllChange = (e: SelectChangeEvent<number[]>) => {
      const value = e.target.value as number[]
      if (value.includes(0)) {
        setValue("area_reservable_all" as keyof AreaFormType, 0)
        setValue(
          "reservable_team_ids",
          companyRelations.teams.map((team) => team.id)
        )
      } else {
        setValue("area_reservable_all" as keyof AreaFormType, 1)
        setValue("reservable_team_ids", value)
      }
    }

    const handleAreaAvailabilityAllChange = (
      e: SelectChangeEvent<number[]>
    ) => {
      const value = e.target.value as number[]
      if (value.includes(0)) {
        setValue("area_availability_all" as keyof AreaFormType, 0)
        setValue(
          "availability_team_ids",
          companyRelations.teams.map((team) => team.id)
        )
      } else {
        setValue("area_availability_all" as keyof AreaFormType, 1)
        setValue("availability_team_ids", value)
      }
    }

    return (
      <>
        <Box
          sx={{
            paddingBottom: "20px",
          }}
        >
          <CustomBox>
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/active-layout.svg`}
              height="20px"
              width="20px"
            />
            <Typography
              sx={{
                ml: 1,
                color: "#22BA9D",
                fontWeight: "bold",
              }}
              variant="subtitle1"
            >
              エリアの設定
            </Typography>
          </CustomBox>
        </Box>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            paddingBottom: "20px",
          }}
          container
          spacing={1}
        >
          <Grid item xs={12} sm={10}>
            <Typography sx={{ color: "#707070" }} variant="caption">
              選択されている席
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography sx={{ color: "#707070" }} variant="caption">
              {areaToSeats.length}席
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography sx={{ color: "#707070" }} variant="caption">
              {areaToSeats
                .map((targetSeat) => {
                  return targetSeat.isAreaSeatDeleteCheck
                    ? null
                    : targetSeat.seat_name
                })
                .flatMap((e) => e ?? [])
                .join(",")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Typography sx={{ color: "#707070" }} variant="caption">
              自由席
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography sx={{ color: "#707070" }} variant="caption">
              {freeSeatSearch().length}席
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9.5}>
            <Typography sx={{ color: "#707070" }} variant="caption">
              エリアの色
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2.5}>
            <Box
              sx={{
                width: "30px",
                height: "30px",
                marginRight: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "2px solid",
                borderColor: "#22BA9D",
                borderRadius: "5px",
              }}
            >
              <Box
                sx={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: `${colorCode}`,
                }}
                onClick={handleColorOpen}
              ></Box>
            </Box>
            {openColor && (
              <Box
                sx={{
                  zIndex: 1000,
                  position: "absolute",
                  top: "100px",
                  right: "400px",
                }}
              >
                <SketchPicker
                  color={colorCode}
                  onChange={(color) => {
                    setColorCode(color.hex)
                    setValue("color_code", color.hex)
                  }}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography sx={{ color: "#707070" }} variant="caption">
              エリア名称を入力してください。
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Controller
              control={control}
              name="area_name"
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  error={!!errors.area_name}
                  helperText={errors.area_name?.message}
                  id="area_name"
                  name="area_name"
                  required
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography sx={{ color: "#707070" }} variant="caption">
              エリアの#属性を入力してください。（複数可）
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} sx={{ mb: 1 }}>
            <Button color="primary" variant="contained" onClick={() => open()}>
              タグを選択する
            </Button>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel htmlFor="tag_ids">属性</InputLabel>
              <Controller
                control={control}
                name="tag_ids"
                render={({ field }) => (
                  <Select
                    {...field}
                    id="tag_ids"
                    name="tag_ids"
                    multiple
                    value={selectdTags}
                    input={
                      <OutlinedInput
                        disabled={true}
                        id="select-multiple-chip"
                        label="Chip"
                      />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((tag) => (
                          <Chip key={tag.id} label={tag.tag_name} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuSelectProps}
                    IconComponent={GreenArrowIcon}
                  ></Select>
                )}
              />
            </FormControl>
          </Grid>
          <Modal open={isOpen} onClose={close}>
            <CustomLayoutModal>
              <Grid container sx={{ padding: "30px" }} spacing={1}>
                {/* タグ選択するのタイトル */}
                <Grid item xs={12} sm={12}>
                  <Typography sx={{ color: "#707070" }} variant="h6">
                    タグを選択する
                  </Typography>
                </Grid>
                {/* タグタブ */}
                <Grid item xs={12} sm={12}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Tabs
                      value={tagCategoryId}
                      onChange={handleTagCategoryChange}
                      variant="scrollable"
                      scrollButtons
                      allowScrollButtonsMobile
                      aria-label="scrollable force tabs example"
                    >
                      {tagCategories.map((tagCategory, i) => {
                        return (
                          <Tab
                            key={i}
                            value={tagCategory.id}
                            label={tagCategory.category_name}
                          />
                        )
                      })}
                    </Tabs>
                  </Box>
                </Grid>
                {/* タグ一覧 */}
                <Grid item xs={12} sm={12} sx={{ margin: "20px 0" }}>
                  <Grid
                    container
                    sx={{
                      padding: "0 30px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {tags.map((tag, i) => {
                      return (
                        <Grid
                          key={i}
                          item
                          xs={4}
                          sm={4}
                          sx={{ wordBreak: "break-word" }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={handleTagCheck}
                                value={tag.id}
                                checked={selectedTagCheck(tag.id)}
                              />
                            }
                            label={tag.tag_name}
                          />
                        </Grid>
                      )
                    })}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <ReflectButton
                    label="戻る"
                    disabled={!isValid}
                    handleClick={close}
                  />
                </Grid>
              </Grid>
            </CustomLayoutModal>
          </Modal>
          <Grid item xs={12} sm={12}>
            <Typography sx={{ color: "#707070" }} variant="caption">
              自動キャンセル時間を選択してください。
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel htmlFor="auto_cancel_times_id">
                自動キャンセル
              </InputLabel>
              <Controller
                control={control}
                name="auto_cancel_times_id"
                render={({ field }) => (
                  <Select
                    {...field}
                    id="auto_cancel_times_id"
                    name="auto_cancel_times_id"
                    value={autoCancelTimeMinutes}
                    defaultValue={autoCancelTimeMinutes}
                    required
                    label="自動キャンセル"
                    onChange={(e) => {
                      handleSetAutoCancelTimeId(e)
                    }}
                    MenuProps={MenuProps}
                    IconComponent={GreenArrowIcon}
                  >
                    <MenuItem key={0} value={0}>
                      自動キャンセルなし
                    </MenuItem>
                    {autoCancelTimes.map((autoCancelTime) => (
                      <MenuItem
                        key={autoCancelTime.id}
                        value={Number(autoCancelTime.id)}
                      >
                        {autoCancelTime.auto_cancel_time}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Typography sx={{ color: "#707070" }} variant="caption">
              エリアを予約可能にしますか？
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              control={control}
              name="is_reservable"
              render={({ field }) => (
                <Switch
                  {...field}
                  name="is_reservable"
                  checked={isReservable}
                  onChange={handleReservableTypeChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              )}
            />
          </Grid>
          {isReservable && (
            <>
              <Grid item xs={12} sm={12}>
                <Typography sx={{ color: "#707070" }} variant="caption">
                  当日・翌日分以降の予約・チェックインが可能な部署を選択してください。
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel htmlFor="reservable_team_ids">
                    所属選択
                  </InputLabel>
                  <Controller
                    control={control}
                    name="reservable_team_ids"
                    render={({ field }) => (
                      <Select
                        {...field}
                        id="reservable_team_ids"
                        name="reservable_team_ids"
                        multiple
                        value={reservableTeamIds}
                        onChange={(e) => {
                          field.onChange(e)
                          handleTeamChange(e)
                          handleAreaReservableAllChange(e)
                        }}
                        input={<OutlinedInput label="所属選択" />}
                        renderValue={() => reservableTeamNames.join(", ")}
                        MenuProps={MenuProps}
                        IconComponent={GreenArrowIcon}
                      >
                        <MenuItem key={0} value={0}>
                          <Checkbox
                            checked={
                              reservableTeamIds.length ===
                              companyRelations.teams.length
                            }
                          />
                          <ListItemText primary={"ALL"} />
                        </MenuItem>
                        {companyRelations.teams.map((team) => (
                          <MenuItem key={team.id} value={Number(team.id)}>
                            <Checkbox
                              checked={
                                reservableTeamIds.indexOf(Number(team.id)) > -1
                              }
                            />
                            <ListItemText primary={team.team_name} />
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography sx={{ color: "#707070" }} variant="caption">
                  当日予約・チェックインが可能な部署を選択してください。
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel htmlFor="availability_team_ids">
                    所属選択
                  </InputLabel>
                  <Controller
                    control={control}
                    name="availability_team_ids"
                    render={({ field }) => (
                      <Select
                        {...field}
                        error={!!errors.availability_team_ids}
                        id="availability_team_ids"
                        name="availability_team_ids"
                        multiple
                        value={availabilityTeamIds}
                        onChange={(e) => {
                          field.onChange(e)
                          handleTeamChange(e)
                          handleAreaAvailabilityAllChange(e)
                        }}
                        input={<OutlinedInput label="所属選択" />}
                        renderValue={() => availabilityTeamNames.join(", ")}
                        MenuProps={MenuProps}
                        IconComponent={GreenArrowIcon}
                      >
                        <MenuItem key={0} value={0}>
                          <Checkbox
                            checked={
                              availabilityTeamIds.length ===
                              companyRelations.teams.length
                            }
                          />
                          <ListItemText primary={"ALL"} />
                        </MenuItem>
                        {companyRelations.teams.map((team) => (
                          <MenuItem key={team.id} value={Number(team.id)}>
                            <Checkbox
                              checked={
                                availabilityTeamIds.indexOf(Number(team.id)) >
                                -1
                              }
                            />
                            <ListItemText primary={team.team_name} />
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography sx={{ color: "#707070" }} variant="caption">
                  エリアのイメージを登録可能です。
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} {...getRootProps()}>
                <Box>
                  <FileUploadIcon>
                    <input {...getInputProps()} />
                  </FileUploadIcon>
                </Box>
                <img src={imgFile} />
              </Grid>
              <Grid item xs={12} sm={9}>
                <Typography sx={{ color: "#707070" }} variant="caption">
                  エリアを会議室化しますか？
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Controller
                  control={control}
                  name="is_area_to_meeting_room"
                  render={({ field }) => (
                    <Switch
                      {...field}
                      name="is_area_to_meeting_room"
                      checked={isAreaToMeetingRoom}
                      onChange={handleAreaToMeetingRoomChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  )}
                />
              </Grid>
              {isAreaToMeetingRoom && (
                <>
                  <Grid item xs={12} sm={12}>
                    <Typography sx={{ color: "#707070" }} variant="caption">
                      予約・利用時間の1回あたりの制限をもうける場合、時間制限を行ってください。行わない場合0を設定して下さい。(分単位)
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Controller
                      control={control}
                      name="available_time"
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          error={!!errors.available_time}
                          helperText={errors.available_time?.message}
                          id="available_time"
                          name="available_time"
                          required
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Typography sx={{ color: "#707070" }} variant="caption">
                      分
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Typography sx={{ color: "#707070" }} variant="caption">
                      所属予約時のメンバー外の制限を許可しますか？
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Controller
                      control={control}
                      name="outside_team_usable"
                      render={({ field }) => (
                        <Switch
                          {...field}
                          name="outside_team_usable"
                          checked={outsideTeamUsable}
                          onChange={handleOutsideTeamUsableChange}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={12}>
                <Typography sx={{ color: "#707070" }} variant="caption">
                  エリアの説明
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controller
                  control={control}
                  name="area_description"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.area_description}
                      helperText={errors.area_description?.message}
                      id="area_description"
                      label="最大文字数1000文字"
                      multiline
                      fullWidth
                      rows={4}
                    />
                  )}
                />
              </Grid>
            </>
          )}
        </Grid>
        <CustomBox sx={{ marginBottom: "40px" }}>
          <Button
            variant="outlined"
            fullWidth
            disabled={!isValid || Object.keys(errors).length > 0}
            onClick={handleSubmit(
              area.id === 0 ? handleCreateArea : handleUpdateArea
            )}
          >
            <Typography
              sx={{
                ml: 1,
                color: "#22BA9D",
                fontWeight: "bold",
              }}
              variant="subtitle1"
            >
              {area.id === 0 ? "保存" : "更新"}
            </Typography>
          </Button>
        </CustomBox>
        <CustomBox sx={{ justifyContent: "center" }}>
          <img
            src={`${process.env.PUBLIC_URL}/images/icons/back.svg`}
            height="20px"
            width="20px"
          />
          <Typography
            sx={{
              ml: 1,
              fontWeight: "bold",
              "&:hover": {
                cursor: "pointer",
                boxShadow: "0 0 4px gray",
              },
            }}
            variant="subtitle1"
            onClick={() => {
              setSidebarTabType("seat")
              setSidebarLayoutComponent("seatList")
              resetAreaSeats
            }}
          >
            一覧へ戻る
          </Typography>
        </CustomBox>
      </>
    )
  }
)
