import React, { useEffect, useState } from "react"
import { Controller } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import {
  Box,
  styled,
  Grid,
  InputLabel,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  Radio,
  FormControlLabel,
  RadioGroup,
  Modal,
  Checkbox,
} from "@mui/material"

import { useContractPlan } from "../../../models/company/useContractPlan"
import { ContractPlan } from "../../../models/company/useContractPlan/type"
import { useCreditCard } from "../../../models/company/useCreditCard"
import { BuyerStatusEnum, usePaid } from "../../../models/company/usePaid"
import { usePlan } from "../../../models/company/usePlan"
import { Plan } from "../../../models/company/usePlan/type"
import { useAgreement } from "../../../models/public/useAgreement"
import { jaDateFormat } from "../../../utils/dateTimeFormat"
import { feeYearCalculation } from "../../../utils/feeYearCalculation"
import { useOpen } from "../../../utils/isOpen"
import { CancelButton } from "../../public/CancelButton"
import { ErrorText } from "../../public/ErrorText"
import { GreenArrowIcon } from "../../public/GreenArrowIcon"
import { Loading } from "../../public/Loading"
import { ReflectButton } from "../../public/ReflectButton"
import { WarningText } from "../../public/WarningText"

const CustomGridContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2.5, 0),
  borderTop: "1px solid rgba(0, 0, 0, 0.12)",
}))

const CustomGridItem = styled(Grid)(() => ({
  display: "flex",
  alignItems: "center",
}))

const CustomLabel = styled(InputLabel)(() => ({
  fontWeight: "bold",
}))

const CustomTypography = styled(Typography)(() => ({
  fontWeight: "bold",
}))

const ContentHeader = styled(Typography)(() => ({
  fontWeight: "bold",
  color: "rgba(0, 0, 0, 0.6)",
}))

const ContentText = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  color: "rgba(0, 0, 0, 0.6)",
  textDecoration: "underline",
  marginBottom: theme.spacing(3),
}))

const CustomLayoutModal = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: "35%",
}))

const handleMonthAccountAmount = (
  plan: Plan | undefined,
  accountCount: number
) => {
  return plan?.account_amount !== undefined &&
    !isNaN(Number(plan?.account_amount * accountCount))
    ? plan?.account_amount * accountCount
    : 0
}

const handleYearAccountAmount = (
  plan: Plan | undefined,
  accountCount: number
) => {
  return plan?.account_amount !== undefined && !isNaN(Number(accountCount))
    ? feeYearCalculation(plan?.account_amount, accountCount)
    : 0
}

const activePlan = (contractPlan: ContractPlan) => {
  switch (contractPlan.plan.plan_type) {
    case "free":
      return `${contractPlan.plan.plan_name} (${jaDateFormat(
        new Date(contractPlan.active_contract.end_date)
      )}まで)`
    case "monthly":
      return `${contractPlan.plan.plan_name} (開始日: ${jaDateFormat(
        new Date(contractPlan.active_contract.start_date)
      )})`
    case "year":
      return `${contractPlan.plan.plan_name} (開始日: ${jaDateFormat(
        new Date(contractPlan.active_contract.start_date)
      )})`
  }
}

export const PaymentPlanForm = () => {
  const navigate = useNavigate()

  const {
    form,
    contractPlan,
    fetchContractPlan,
    updateContractPlan,
    errorMessages,
  } = useContractPlan()

  const [loading, setLoading] = useState<boolean>(true)
  const { isOpen, open, close } = useOpen()
  const { plans, fetchPlans } = usePlan()
  const { onFetchCreditCard } = useCreditCard()
  const { onJudgingPaid } = usePaid()
  const { privacyChecked, paidChecked, handlePaidChange, handlePrivacyChange } =
    useAgreement()

  const isWithdrawal = contractPlan?.withdrawal_application_date == null

  const errors = form.formState.errors

  const yearPlan = plans.find((plan) => {
    return plan.plan_type === "year"
  })

  const monthlyPlan = plans.find((plan) => {
    return plan.plan_type === "monthly"
  })

  const yearAmount = handleYearAccountAmount(
    yearPlan,
    form.getValues("account_count")
  )

  const date = new Date()
  const end_of_month = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  ).getDate()

  const monthlyAmount = handleMonthAccountAmount(
    monthlyPlan,
    form.getValues("account_count")
  )

  const isSameYearAndMonth = (date1: Date, date2: Date) => {
    const d1 = new Date(date1)
    const d2 = new Date(date2)
    return (
      d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth()
    )
  }

  // 年契約の最終月を算出するメソッド
  const isYearPlanEnd = (startDate: Date) => {
    const today = new Date()
    const start = new Date(startDate)
    const yearSinceStart = today.getFullYear() - start.getFullYear()
    const isSameMonth = today.getMonth() === start.getMonth()

    return yearSinceStart >= 1 && isSameMonth
  }

  const differenceAmount = (monthlyAmount - yearAmount) * 12

  useEffect(() => {
    const planState = async () => {
      await Promise.all([fetchContractPlan(), fetchPlans()])
    }
    planState().then(() => {
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    const paymentState = async () => {
      await Promise.all([onFetchCreditCard(), onJudgingPaid()])
    }
    paymentState()
  }, [])

  return (
    <>
      {loading ? (
        <>
          <Loading type="content" />
        </>
      ) : (
        <>
          {contractPlan?.withdrawal_application_date && (
            <Grid item sx={{ mb: 2 }} xs={12} sm={12}>
              {WarningText([
                "解約を申し込んでいる場合、プランの変更はできません",
              ])}
            </Grid>
          )}
          <CustomGridContainer container>
            <CustomGridItem item xs={12} sm={2}>
              <CustomLabel>現在の利用プラン</CustomLabel>
            </CustomGridItem>
            <Grid item xs={12} sm={8}>
              <CustomTypography>{activePlan(contractPlan)}</CustomTypography>
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer container>
            <CustomGridItem item xs={2} sm={2}>
              <CustomLabel htmlFor="plan_id">利用プラン</CustomLabel>
            </CustomGridItem>
            <Grid item xs={4} sm={4}>
              <FormControl fullWidth>
                <Controller
                  control={form.control}
                  name="plan_id"
                  render={({ field }) => (
                    <Select
                      {...field}
                      error={!!errors.plan_id}
                      id="plan_id"
                      name="plan_id"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      sx={{
                        background: "rgba(112,112,112,0.05)",
                      }}
                      IconComponent={GreenArrowIcon}
                    >
                      <MenuItem disabled={true} key={0} value={0}>
                        選択する
                      </MenuItem>
                      {plans.map((plan) => (
                        <MenuItem
                          key={plan.id}
                          value={plan.id}
                          disabled={plan.plan_type === "free"}
                        >
                          {plan.plan_name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <FormHelperText error={!!errors.plan_id?.message}>
                  {errors.plan_id?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer container>
            <CustomGridItem item xs={2} sm={2}>
              <CustomLabel htmlFor="account_count">ライセンス数</CustomLabel>
            </CustomGridItem>
            <Grid item xs={2} sm={2}>
              <Controller
                control={form.control}
                name="account_count"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.account_count}
                    helperText={errors.account_count?.message}
                    required
                    id="account_count"
                    name="account_count"
                    fullWidth
                    value={form.watch("account_count")}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              {WarningText(["現在のライセンス数が変更されます"])}
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer container>
            <CustomGridItem item xs={2} sm={2}>
              <CustomLabel htmlFor="price">ご利用料金</CustomLabel>
            </CustomGridItem>
            <Grid item xs={2.5} sm={2.5} sx={{ paddingRight: "10px" }}>
              <Box
                sx={{
                  backgroundColor: "#F7F7F7",
                  padding: "10px",
                  border: "1px solid rgba(0, 0, 0, 0.12)",
                }}
              >
                <ContentHeader>年間契約</ContentHeader>
              </Box>
              <Box
                sx={{
                  padding: "20px",
                  border: "1px solid rgba(0, 0, 0, 0.12)",
                  maxHeight: "250px",
                }}
              >
                <ContentText>{`￥${(
                  yearAmount * 12
                ).toLocaleString()}/年`}</ContentText>
                <ContentText>{`￥${yearAmount.toLocaleString()}/月`}</ContentText>
                {contractPlan.plan.plan_type === "free" && (
                  <ContentText>{`※初月日割り￥${Math.round(
                    ((end_of_month - date.getDate() + 1) * yearAmount) /
                      end_of_month
                  ).toLocaleString()}`}</ContentText>
                )}
                <Box
                  sx={{
                    color: "#EC940F",
                    padding: "5px",
                    borderRadius: "20px",
                    border: "1px solid #c1c1c1",
                    fontWeight: "bold",
                  }}
                >{`年間￥${
                  differenceAmount ? differenceAmount.toLocaleString() : 0
                }お得`}</Box>
              </Box>
            </Grid>
            <Grid item xs={2.5} sm={2.5}>
              <Box
                sx={{
                  backgroundColor: "#F7F7F7",
                  padding: "10px",
                  border: "1px solid rgba(0, 0, 0, 0.12)",
                }}
              >
                <ContentHeader>月契約</ContentHeader>
              </Box>
              <Box
                sx={{
                  padding: "20px",
                  border: "1px solid rgba(0, 0, 0, 0.12)",
                  maxHeight: "250px",
                }}
              >
                <ContentText>{`￥${(
                  monthlyAmount * 12
                ).toLocaleString()}/年`}</ContentText>
                <ContentText>{`￥${monthlyAmount.toLocaleString()}/月`}</ContentText>
                {contractPlan.plan.plan_type === "free" && (
                  <ContentText>{`※初月日割り￥${Math.round(
                    ((end_of_month - date.getDate() + 1) * monthlyAmount) /
                      end_of_month
                  ).toLocaleString()}`}</ContentText>
                )}
              </Box>
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer container>
            <CustomGridItem item xs={2} sm={2}>
              <CustomLabel htmlFor="payment_methods">決済方法</CustomLabel>
            </CustomGridItem>
            <Grid item xs={10} sm={10}>
              {contractPlan.payment_methods === "credit_card" && (
                <CustomTypography>クレジットカード</CustomTypography>
              )}
              {contractPlan.payment_methods === "paid" && (
                <CustomTypography>銀行振込</CustomTypography>
              )}
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer container>
            <Grid item xs={12} sm={12}>
              <Checkbox
                checked={privacyChecked}
                onChange={handlePrivacyChange}
              />
              <a
                href="https://workagile.jp/index.php/privacy/"
                target="_blank"
                rel="noreferrer"
              >
                プライバシーポリシー
              </a>
              と
              <a
                href="https://workagile.jp/index.php/terms/"
                target="_blank"
                rel="noreferrer"
              >
                利用規約
              </a>
              に同意します
            </Grid>
            {contractPlan.payment_methods === "paid" && (
              <Grid item xs={12} sm={12}>
                <Checkbox checked={paidChecked} onChange={handlePaidChange} />
                <a
                  href="https://paid.jp/v/contents/pre/guide/rules.jsp"
                  target="_blank"
                  rel="noreferrer"
                >
                  銀行振込の利用規約に同意します
                </a>
              </Grid>
            )}
          </CustomGridContainer>
          <Box>
            {WarningText([
              "月プラン・年プランの変更・切り替えは月に1回のみです",
            ])}
          </Box>
          <Box
            sx={{
              width: "50%",
              margin: "10px auto",
            }}
          >
            {errorMessages.length > 0 && ErrorText(errorMessages)}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "450px",
              m: "30px auto",
            }}
          >
            <CancelButton
              label="戻る"
              handleClick={() => navigate("/company/settings/payment")}
            />
            <ReflectButton
              label="確認"
              disabled={
                !form.formState.isValid ||
                !privacyChecked ||
                !(
                  (paidChecked && contractPlan.payment_methods === "paid") ||
                  contractPlan.payment_methods === "credit_card"
                ) ||
                !isWithdrawal ||
                (contractPlan.active_contract.contract_description !==
                  "無料契約" &&
                  isSameYearAndMonth(
                    new Date(),
                    new Date(contractPlan.active_contract.created_at)
                  )) ||
                (contractPlan.active_contract.contract_description ===
                  "年契約" &&
                  !isYearPlanEnd(contractPlan.active_contract.start_date))
              }
              handleClick={() => {
                open()
              }}
            />
          </Box>
          <Modal open={isOpen} onClose={close}>
            <CustomLayoutModal>
              <Box sx={{ padding: "30px 30px 20px" }}>
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mb: "20px",
                  }}
                  variant="h6"
                >
                  ご登録内容にお間違いないでしょうか？
                </Typography>
                <Grid item xs={12} sm={12}>
                  {WarningText([
                    "◉ライセンス数の利用料金は毎月25日時点で確定になります",
                    "◉年契約から月契約に変更する場合、年契約の契約終了日以降に適用されます",
                  ])}
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "450px",
                    m: "30px auto",
                  }}
                >
                  <CancelButton label="キャンセル" handleClick={close} />
                  <ReflectButton
                    label="確定する"
                    handleClick={async (e) => {
                      await updateContractPlan()
                      close()
                    }}
                  />
                </Box>
              </Box>
            </CustomLayoutModal>
          </Modal>
        </>
      )}
    </>
  )
}
