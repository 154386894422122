import parse from "html-react-parser"
import rehypeRaw from "rehype-raw"
import rehypeSanitize from "rehype-sanitize"
import remarkGfm from "remark-gfm"

import React, { useState, useContext } from "react"
import ReactMarkdown from "react-markdown"
import { useNavigate } from "react-router-dom"

import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import MailOutlineIcon from "@mui/icons-material/MailOutline"
import { Box, Modal, styled, Typography, Button } from "@mui/material"

import { theme } from "../../../../config/theme"
import { useLanguage } from "../../../../contexts/LanguageContext"
import { AcceptStatusRequestType } from "../../../../models/employee/useSchedule/days/type"
import { deleteSchedule } from "../../../../models/employee/useSchedule/show"
import { ScheduleInformation } from "../../../../models/employee/useSchedule/show/type"
import { useCompanyRelations } from "../../../../models/public/useCompanyRelations"
import { AuthContext } from "../../../../providers/AuthProvider"
import enTranslations from "../../../../translations/mobileHome/mobileHomeShowScheduleModal/en"
import jaTranslations from "../../../../translations/mobileHome/mobileHomeShowScheduleModal/ja"
import { dateStatus } from "../../../../utils/date"
import { MobileEditEventSchedule } from "../MobileEditEventSchedule"
import { MobileRecurringDeleteModalComponent } from "../MobileRecurringDeleteModal"

const CustomTotalBox = styled(Box)(() => ({
  overflowY: "scroll",
  maxHeight: "95%",
  "&::-webkit-scrollbar": {
    width: "4px",
    height: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.spacing(2),
  },
  marginRight: theme.spacing(-1),
  paddingRight: theme.spacing(1),
}))

const CustomHeaderBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: theme.spacing(0.5),
}))

const CustomTitleBox = styled(Box)(() => ({
  height: "30px",
  display: "flex",
  alignItems: "center",
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(0.5),
}))

const CustomTitle = styled(Typography)(() => ({
  fontWeight: "bolder",
  color: theme.palette.primary.main,
}))

const CustomContentBox = styled(Box)(() => ({
  borderRadius: theme.spacing(1),
  backgroundColor: "rgb(247,247,247)",
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(1.5),
  padding: theme.spacing(1),
  overflowX: "scroll",
}))

const CustomMessageBox = styled(Box)(() => ({
  maxHeight: "50px",
  borderRadius: theme.spacing(1),
  backgroundColor: "rgb(247,247,247)",
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1),
  fontSize: theme.spacing(0.8),
  whiteSpace: "pre-wrap",
  overflowY: "scroll",
}))

const CustomIconBox = styled(Box)(() => ({
  display: "inline-block",
  borderRadius: "50%",
  textAlign: "center",
  height: "30px",
  width: "30px",
  color: "#22BA9D",
  fontWeight: "bolder",
  backgroundColor: "rgb(34, 186, 157, 0.1)",
  marginLeft: theme.spacing(1.5),
}))

const EmployeeListBox = styled(Box)(() => ({
  overflowY: "scroll",
  maxHeight: "60px",
  "&::-webkit-scrollbar": {
    width: "8px",
    height: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.spacing(2),
  },
  marginBottom: theme.spacing(2),
  paddingLeft: theme.spacing(1),
}))

const PlaceListBox = styled(Box)(() => ({
  overflowY: "scroll",
  maxHeight: "50px",
  "&::-webkit-scrollbar": {
    width: "8px",
    height: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.spacing(2),
  },
  marginBottom: theme.spacing(2),
  paddingLeft: theme.spacing(1),
}))

const CustomApproveTypography = styled(Typography)(() => ({
  textAlign: "center",
  fontSize: "0.9rem",
  marginTop: "1rem",
}))

const englishWeekDays = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
]

interface Props {
  date: Date
  close: () => void
  onApproveSubmit: (data: AcceptStatusRequestType) => void
  scheduleInformation: ScheduleInformation
}

export const MobileScheduleShowList: React.FC<Props> = ({
  date,
  close,
  onApproveSubmit,
  scheduleInformation,
}: Props) => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const navigate = useNavigate()
  const { dayOfWeek } = dateStatus()
  const { currentUser } = useContext(AuthContext)
  const { companyRelations } = useCompanyRelations()

  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)
  const [recurringDeleteModal, setRecurringDeleteModal] =
    useState<boolean>(false)

  const myScheduleData = scheduleInformation.schedules.find((schedule) => {
    return schedule.userable.email === currentUser?.attributes.email
  })

  const MobileTimeFormat = (time: Date) => {
    const timeString = time.toLocaleString()
    return new Date(timeString.replace(/-/g, "/"))
  }
  // 既存のMobileTimeFormat関数を使用して日付オブジェクトを生成
  const startDateObj = MobileTimeFormat(
    new Date(scheduleInformation.start_date)
  )
  const endDateObj = MobileTimeFormat(new Date(scheduleInformation.end_date))

  // 日付と曜日を表示するための関数
  const formatDateWithWeekday = (dateObj: Date) => {
    const day = dateObj.getDate()
    const month = dateObj.getMonth() + 1
    const dayOfWeek = translations.weekdays[dateObj.getDay()]
    return `${month}月${day}日(${dayOfWeek})`
  }

  const toDoubleDigits = (num: number) => {
    let numString = String(num)
    if (numString.length === 1) {
      numString = "0" + numString
    }
    return numString
  }

  const weekDaysStr = () => {
    let weekDaysStr = ""
    englishWeekDays.forEach((weekDay, index) => {
      if (
        scheduleInformation.weekly_recurring_type &&
        eval(`scheduleInformation.weekly_recurring_type.${weekDay}`)
      ) {
        weekDaysStr += translations.weekdays[index] + "・"
      }
    })
    if (scheduleInformation.weekly_recurring_type.every_week === 1) {
      weekDaysStr = translations.everyWeek + " " + weekDaysStr
    } else {
      weekDaysStr =
        `${scheduleInformation.weekly_recurring_type.every_week}${translations.weekly}` +
        " " +
        weekDaysStr
    }
    return weekDaysStr.slice(0, -1)
  }

  // 会議室予約の場合、全体で1つ分の表示
  const duplicatedMeetingRooms = scheduleInformation.schedules.filter(
    (schedule, index, self) => {
      return (
        self.findIndex((value) => {
          if (
            schedule.reservable &&
            schedule.reservable.reservable_type === "MeetingRoom"
          ) {
            return (
              schedule.reservable.reservable_type ===
              value.reservable.reservable_type
            )
          }
        }) === index
      )
    }
  )

  const editModalOpen = () => {
    setIsEditModalOpen(true)
  }

  const editModalClose = () => {
    setIsEditModalOpen(false)
  }

  const today = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  )

  // ステータスタイトル言語切り替え
  type ScheduleStatuses = {
    [key: string]: string
  }

  type Translations = {
    schedule_statuses: ScheduleStatuses
  }

  const getLocalizedStatusText = (status: string) => {
    const translations: Translations =
      language === "en" ? enTranslations : jaTranslations
    return translations.schedule_statuses[status] || status
  }

  return (
    <>
      <CustomTotalBox>
        <CustomHeaderBox>
          <Button
            onClick={close}
            color="secondary"
            sx={{ margin: "0", minWidth: "0" }}
          >
            <HighlightOffIcon />
          </Button>
          {scheduleInformation.schedule_creator_flag &&
            !scheduleInformation.is_microsoft &&
            new Date(scheduleInformation.schedules[0].scheduled_date) >=
              today && (
              <Box sx={{ overflowY: "scroll" }}>
                {((scheduleInformation.schedules.length === 1 &&
                  scheduleInformation.schedules[0].schedule_type === "event") ||
                  (scheduleInformation.schedules[0].schedule_type ===
                    "status" &&
                    scheduleInformation.schedules[0].reservable)) && (
                  <>
                    <Button
                      color="primary"
                      variant="outlined"
                      size="small"
                      onClick={editModalOpen}
                    >
                      {translations.Edit}
                    </Button>
                    <Modal
                      open={isEditModalOpen}
                      onClose={editModalClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <MobileEditEventSchedule
                        editModalClose={editModalClose}
                        schedule={scheduleInformation}
                        companyRelations={companyRelations}
                      />
                    </Modal>
                  </>
                )}
                <Button
                  color="primary"
                  variant="outlined"
                  size="small"
                  sx={{ marginLeft: "1rem" }}
                  onClick={() => {
                    if (
                      scheduleInformation.weekly_recurring_type ||
                      scheduleInformation.monthly_recurring_type
                    ) {
                      setRecurringDeleteModal(true)
                    } else {
                      deleteSchedule(
                        scheduleInformation.id,
                        "all_schedule",
                        date
                      ).then(() => {
                        navigate("/mobile/home")
                        window.location.reload()
                        localStorage.setItem(
                          "alertContent",
                          translations.ScheduleDelete
                        )
                      })
                    }
                  }}
                >
                  {translations.DeleteSchedule}
                </Button>
              </Box>
            )}
        </CustomHeaderBox>
        <CustomContentBox>
          {getLocalizedStatusText(scheduleInformation.schedule_title)}
        </CustomContentBox>
        <CustomTitleBox>
          <img
            src={`${process.env.PUBLIC_URL}/images/icons/active-calendar.svg`}
            style={{
              height: "1.2rem",
              marginRight: "1rem",
            }}
          />
          {myScheduleData &&
            (!scheduleInformation.weekly_recurring_type &&
            MobileTimeFormat(scheduleInformation.end_time).getDate() !=
              date.getDate() ? (
              <Typography
                id="modal-modal-description"
                sx={{ mt: 1, fontSize: "0.8rem", color: "#707070" }}
              >
                {formatDateWithWeekday(startDateObj)}
                {MobileTimeFormat(myScheduleData.start_time).getHours()}:
                {toDoubleDigits(
                  MobileTimeFormat(myScheduleData.start_time).getMinutes()
                )}{" "}
                ~ {formatDateWithWeekday(endDateObj)}
                {MobileTimeFormat(myScheduleData.end_time).getHours()}:
                {toDoubleDigits(
                  MobileTimeFormat(myScheduleData.end_time).getMinutes()
                )}
              </Typography>
            ) : (
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "0.8rem", color: "#707070" }}
              >
                <Typography
                  id="modal-modal-description"
                  sx={{ fontSize: "0.8rem", color: "#707070" }}
                >
                  {formatDateWithWeekday(startDateObj)}
                  {MobileTimeFormat(myScheduleData.start_time).getHours()}:
                  {toDoubleDigits(
                    MobileTimeFormat(myScheduleData.start_time).getMinutes()
                  )}{" "}
                  ~ {formatDateWithWeekday(endDateObj)}
                  {MobileTimeFormat(myScheduleData.end_time).getHours()}:
                  {toDoubleDigits(
                    MobileTimeFormat(myScheduleData.end_time).getMinutes()
                  )}
                </Typography>
              </Typography>
            ))}
          {scheduleInformation.weekly_recurring_type &&
            scheduleInformation.weekly_recurring_type.id != 0 && (
              <Typography
                id="modal-modal-description"
                sx={{ ml: 1, fontSize: "0.8rem", color: "#707070" }}
              >
                {weekDaysStr()}
                {translations.week}
              </Typography>
            )}
        </CustomTitleBox>
        {scheduleInformation.schedules[0] && (
          <CustomTitleBox>
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/key.svg`}
              style={{
                height: "1.2rem",
                color: "#22BA9D",
                marginRight: "1rem",
              }}
            />
            {scheduleInformation.schedules[0].is_secret
              ? translations.private
              : translations.public}
          </CustomTitleBox>
        )}
        <CustomTitleBox>
          <MailOutlineIcon
            color="primary"
            sx={{
              height: "1.2rem",
              padding: "0 5px 0 0",
            }}
          />
          <CustomTitle marginLeft={1}>{translations.Message}</CustomTitle>
        </CustomTitleBox>
        <CustomMessageBox>
          <ReactMarkdown
            rehypePlugins={[rehypeRaw, rehypeSanitize]}
            remarkPlugins={[remarkGfm]}
          >
            {scheduleInformation.message_content}
          </ReactMarkdown>
        </CustomMessageBox>
        <CustomTitleBox>
          <CustomTitle>{translations.Attendees}</CustomTitle>
          <CustomIconBox>
            <Typography lineHeight="30px">
              {scheduleInformation.schedules.length}
            </Typography>
          </CustomIconBox>
        </CustomTitleBox>
        <EmployeeListBox>
          {scheduleInformation.schedules.map((schedule, index) => (
            <>
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Typography fontSize="0.8rem" mr="0.5rem">
                    {schedule.userable?.last_name ?? ""}
                  </Typography>
                  <Typography fontSize="0.8rem">
                    {schedule.userable?.first_name ?? ""}
                    {schedule.userable?.userable_type === "Guest" && (
                      <span>{translations.gest}</span>
                    )}
                  </Typography>
                </Box>
                <Typography fontSize="0.8rem">
                  {schedule.userable?.userable_type === "Employee" &&
                    (schedule.accept_status
                      ? translations.Accepted
                      : translations.Declined)}
                </Typography>
              </Box>
            </>
          ))}
        </EmployeeListBox>
        <CustomTitleBox>
          <CustomTitle>{translations.DesksRooms}</CustomTitle>
        </CustomTitleBox>
        <PlaceListBox>
          {(duplicatedMeetingRooms.length == 0
            ? scheduleInformation.schedules
            : duplicatedMeetingRooms
          ).map((schedule, index) => (
            <>
              <Box key={index} display="flex">
                <Typography fontSize="0.9rem">
                  {schedule.reservable &&
                    schedule.reservable.floor.branch.branch_name}
                </Typography>
                <Typography fontSize="0.9rem" margin="0 1rem">
                  {schedule.reservable &&
                    `${schedule.reservable.floor.floor_number}F`}
                </Typography>
                <Typography fontSize="0.9rem">
                  {schedule.reservable && `#${schedule.reservable.name}`}
                </Typography>
              </Box>
            </>
          ))}
        </PlaceListBox>
        {scheduleInformation.accept_flag &&
          !scheduleInformation.schedule_creator_flag &&
          myScheduleData &&
          !scheduleInformation.is_microsoft && (
            <>
              <CustomApproveTypography>
                {translations.invitation}
              </CustomApproveTypography>
              <Box display="flex" justifyContent="center" my={1.5}>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ marginRight: "1rem" }}
                  onClick={() => {
                    onApproveSubmit({
                      id: myScheduleData.id,
                      accept_status: false,
                    })
                  }}
                  disabled={!myScheduleData.accept_status}
                >
                  {translations.Decline}
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    onApproveSubmit({
                      id: myScheduleData.id,
                      accept_status: true,
                    })
                  }}
                  disabled={myScheduleData.accept_status}
                >
                  {translations.Accept}
                </Button>
              </Box>
            </>
          )}
        {(scheduleInformation.weekly_recurring_type ||
          scheduleInformation.monthly_recurring_type) && (
          <MobileRecurringDeleteModalComponent
            date={date}
            schedule_information_id={scheduleInformation.id}
            recurringDeleteModal={recurringDeleteModal}
            setRecurringDeleteModal={setRecurringDeleteModal}
          />
        )}
      </CustomTotalBox>
    </>
  )
}
