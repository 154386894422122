import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

import { SelectChangeEvent } from "@mui/material"

import { fetchEmployeeLayoutRequest } from "../../../api/employee/layoutRequest"
import { useLanguage } from "../../../contexts/LanguageContext"
import { useWorkHour } from "../../../models/company/useWorkHour"
import enTranslations from "../../../translations/employeeStatus/employeeStatusHeader/en"
import jaTranslations from "../../../translations/employeeStatus/employeeStatusHeader/ja"
import { dateStatus } from "../../../utils/date"
import {
  formatDateForSafari,
  formatDateForLocale,
} from "../../../utils/dateTimeFormat"
import { downloadIcon } from "../../../utils/downLoadIcon"
import { downloadLayoutImage } from "../../../utils/downloadLayoutImage"
import { fifteenMinutesIntervalHours } from "../../../utils/hours"
import { useCompanyRelations } from "../../public/useCompanyRelations"
import { useSearchSchedules } from "../useSchedule/searchPlace"
import {
  SeatObjectType,
  MeetingRoomType,
  AreaType,
  EmployeeLayoutRequestType,
  EmployeeLayoutLocationType,
  LayoutType,
  Schedule,
} from "./type"

// TODO エリアの描画実装が終わり次第不要なら削除
/* 定数
  SPECIFIC: 特定
  ALL: 全て
  DEFAULT_COLOR_CODE: エリア・会議室の枠線の色
*/
export const SPECIFIC = 0
export const ALL = 1
export const DEFAULT_COLOR_CODE = "#F4B461"

/* 初期値
  meetingRoomData: 会議室の初期値
  meetingRoomFormData: 会議室フォームの初期値
*/
const meetingRoomData: MeetingRoomType = {
  id: 0,
  floor_id: 0,
  meeting_room_code: "",
  meeting_room_name: "",
  capacity: 0,
  availability_type: SPECIFIC,
  reservable_type: SPECIFIC,
  availability_team_ids: [],
  reservable_team_ids: [],
  available_time: 0,
  meeting_room_description: "",
  outside_team_usable: false,
  start_x: 100,
  start_y: 100,
  end_x: 200,
  end_y: 200,
  color_code: DEFAULT_COLOR_CODE,
  isDelete: false,
  schedule_par_date_list: [],
  is_highlight: false,
  auto_cancel_times_id: 0,
}

const areaData: AreaType = {
  id: 0,
  floor_id: 0,
  area_name: "",
  area_type: 0,
  is_reservable: false,
  availability_type: 0,
  reservable_type: 0,
  area_description: "",
  start_x: 0,
  start_y: 0,
  end_x: 0,
  end_y: 0,
  color_code: "",
  is_area_to_meeting_room: false,
  availability_team_ids: [],
  reservable_team_ids: [],
  auto_cancel_times_id: 0,
  // area_to_meeting_room: {
  //   available_time: 0,
  //   outside_team_usable: false,
  //   meeting_room_code: ""
  // },
  // children: [{
  //   id: 0,
  //   floor_id: 0,
  //   area_id: 0,
  //   seat_name: "",
  //   available_time_type: 0,
  //   seat_type: 0,
  //   x: 0,
  //   y: 0,
  // }]
}

const layoutData: LayoutType = {
  x: 0,
  y: 0,
  scale: 0,
}

// TODO エリアの描画実装が終わり次第不要なら削除

// レイアウト
export const useLayout = () => {
  const companyRelationObject = useCompanyRelations()
  const companyRelations = companyRelationObject.companyRelations
  const locationState = useLocation().state as EmployeeLayoutLocationType
  const [branchId, setBranchId] = useState<number>(0)
  const [floorId, setFloorId] = useState<number>(0)

  const [wholeDayFlag, setWholeDayFlag] = useState<boolean>(
    locationState ? locationState?.wholeDayFlag : false
  )

  const { workHour, fetchWorkHour } = useWorkHour()

  const [errorMessages] = useState<string[]>([])
  const [layoutImg, setLayoutImg] = useState<string>("")
  const [layout, setLayout] = useState<LayoutType>(layoutData)
  const [seats, setSeats] = useState<SeatObjectType[]>([])
  const [meetingRooms, setMeetingRooms] = useState<MeetingRoomType[]>([])
  const [meetingRoom, setMeetingRoom] =
    useState<MeetingRoomType>(meetingRoomData)
  const [area] = useState<AreaType>(areaData)
  const [areas, setAreas] = useState<AreaType[]>([])
  const [sidebarLayoutComponent, setSidebarLayoutComponent] =
    useState<string>("seatList") // レイアウトのサイドバーの表示内容を切り替えにときに使用する
  const [selectedSeat, setSelectedSeat] = useState<SeatObjectType | undefined>(
    undefined
  )
  const [selectedMeetingRoom, setSelectedMeetingRoom] = useState<
    MeetingRoomType | undefined
  >(undefined)

  const { searchSchedule } = useSearchSchedules()

  const [userNotExists, setUserNotExists] = useState<boolean>(false)
  const [userNotExistsMessage, setUserNotExistsMessage] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)

  const {
    date,
    setDate,
    previousDate,
    nextDate,
    previousWeekday,
    nextWeekday,
    weekdayNumber,
    setWeekdayNumber,
    dayOfWeek,
    startDate,
    endDate,
    formatDate,
    setStartDate,
    setEndDate,
  } = dateStatus(locationState ? locationState?.date : new Date())
  const [timeIndex, setTimeIndex] = useState<number>(
    locationState ? locationState?.timeIndex : 0
  )
  // const history = useHistory()
  const time = fifteenMinutesIntervalHours[timeIndex]

  const findBranch = (branchId: number) => {
    return companyRelations.branches.filter(
      (branch) => branch.id === branchId
    )[0]
  }
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const selectedBranch = findBranch(branchId)

  // モード切り替えで遷移した場合はlocationStateから、それ以外の手段でアクセスした場合は主拠点=branchIdとする
  useEffect(() => {
    if (companyRelationObject.branchId > 0) {
      setBranchId(
        locationState ? locationState?.branchId : companyRelationObject.branchId
      )
    }
  }, [companyRelationObject.branchId])

  useEffect(() => {
    if (companyRelationObject.floorId > 0) {
      // レイアウト予約→席状況と遷移した場合locationStateからfloorIdを引き継ぐ
      // 状況→席状況と遷移した場合、フロアのプルダウンが存在しないためlocationState.floorIdが0(フロアが未選択)になる。
      setFloorId(
        locationState ? locationState?.floorId : companyRelationObject.floorId
      )
    }
  }, [companyRelationObject.floorId])

  useEffect(() => {
    const planState = async () => {
      await Promise.all([fetchWorkHour()])
    }
    planState().then(() => {
      setLoading(false)
    })
  }, [])

  // 最新のレイアウト情報の取得
  const fetchLayout = async (
    params: EmployeeLayoutRequestType,
    companyId: number
  ) => {
    setLoading(true)
    try {
      const response = await fetchEmployeeLayoutRequest({
        branch_id: params.branch_id,
        floor_id: params.floor_id,
        start_date: params.start_date,
        end_date: params.end_date,
      })

      if (response.data && response.status === 200) {
        await Promise.all(
          response.data.seats.map(async (oldSeat) => {
            await Promise.all(
              oldSeat.schedule_par_date_list.map(async (schedule_par_date) => {
                await Promise.all(
                  schedule_par_date.schedules.map(async (schedule) => {
                    if (schedule.userable?.icon) {
                      const resultImage = await downloadIcon(
                        companyId,
                        schedule.userable.id,
                        schedule.userable.icon
                      )
                      schedule.image_blob = resultImage ? resultImage : ""
                    }
                  })
                )
              })
            )
          })
        )

        await Promise.all(
          response.data.meeting_rooms.map(async (oldMeetingRoom) => {
            await Promise.all(
              oldMeetingRoom.schedule_par_date_list.map(
                async (schedule_par_date) => {
                  await Promise.all(
                    schedule_par_date.schedules.map(async (schedule) => {
                      if (schedule.userable?.icon) {
                        const resultImage = await downloadIcon(
                          companyId,
                          schedule.userable.id,
                          schedule.userable.icon
                        )
                        schedule.image_blob = resultImage ? resultImage : ""
                      }
                    })
                  )
                }
              )
            )
          })
        )

        setSeats(response.data.seats)
        setMeetingRooms(response.data.meeting_rooms)
        setAreas(response.data.areas)

        if (response.data.floor.layout_image) {
          const result = await downloadLayoutImage(
            companyId,
            params.branch_id,
            params.floor_id,
            response.data.floor.layout_image
          )
          if (result) {
            setLayoutImg(URL.createObjectURL(result.Body as Blob))
            setLayout({
              x: response.data.x,
              y: response.data.y,
              scale: response.data.scale,
            })
          }
        } else {
          setLayoutImg("")
          setLayout(layoutData)
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  // 座席の予定を指定した日時でfilterする
  const filterSeatSchedules = (scheduled_date: string, start_time: string) => {
    const newSeatArray = seats
      .map((seat) => {
        const scheduleFilteredByDate = seat.schedule_par_date_list.filter(
          (schedule_par_date) => {
            return schedule_par_date.scheduled_date === scheduled_date
          }
        )[0]

        if (!scheduleFilteredByDate?.schedules) return null

        const scheduleFilteredByTime = scheduleFilteredByDate.schedules.filter(
          (schedule) => {
            const targetStartTime = new Date(
              `${scheduled_date.replace(/-/g, "/")} ${start_time}`
            ).getTime()
            const startTimeToString = schedule.start_time
              .toLocaleString()
              .replace(/-/g, "/")
            const endTimeToString = schedule.end_time
              .toLocaleString()
              .replace(/-/g, "/")
            const startTimeNumber = new Date(startTimeToString).getTime()
            const endTimeNumber = new Date(endTimeToString).getTime()
            return (
              startTimeNumber <= targetStartTime &&
              endTimeNumber > targetStartTime
            )
          }
        )[0]

        return { ...seat, schedule: scheduleFilteredByTime } // scheduleプロパティに設定した予定がレイアウト上に反映される
      })
      .flatMap((e) => e ?? [])
    setSeats(newSeatArray)
  }

  // 会議室の予定を日時でfilterする
  const filterMeetingRoomSchedules = (
    scheduled_date: string,
    start_time: string
  ) => {
    const newMeetingRoomArray = meetingRooms
      .map((meetingRoom) => {
        const scheduleFilteredByDate =
          meetingRoom.schedule_par_date_list.filter((schedule_par_date) => {
            return schedule_par_date.scheduled_date === scheduled_date
          })[0]

        if (!scheduleFilteredByDate?.schedules) return null

        const schedulesFilteredByTime = scheduleFilteredByDate.schedules.filter(
          (schedule) => {
            const targetStartTime = new Date(
              `${scheduled_date.replace(/-/g, "/")} ${start_time}`
            ).getTime()
            const startTimeToString = schedule.start_time
              .toLocaleString()
              .replace(/-/g, "/")
            const endTimeToString = schedule.end_time
              .toLocaleString()
              .replace(/-/g, "/")
            const startTimeNumber = new Date(startTimeToString).getTime()
            const endTimeNumber = new Date(endTimeToString).getTime()
            return (
              startTimeNumber <= targetStartTime &&
              endTimeNumber > targetStartTime
            )
          }
        )

        return { ...meetingRoom, schedules: schedulesFilteredByTime } // schedulesプロパティに設定した予定がレイアウト上に反映される
      })
      .flatMap((e) => e ?? [])

    setMeetingRooms(newMeetingRoomArray)
  }

  // 終日選択の場合は就業時間でfilterする
  const filterSchedulesByWorkHour = (schedules: Schedule[]) => {
    return schedules.filter((schedule) => {
      // 予約時間の取得
      const scheduleStartTime = new Date(
        formatDateForSafari(schedule.start_time)
      )
      const scheduleEndTime = new Date(formatDateForSafari(schedule.end_time))

      const scheduleStartTimeString = scheduleStartTime.toLocaleTimeString(
        "ja-JP",
        {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        }
      )

      const scheduleEndTimeString = scheduleEndTime.toLocaleTimeString(
        "ja-JP",
        {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        }
      )
      // 就業時間の取得
      const workStartTime = new Date(
        formatDateForSafari(workHour.am_start_time.toString())
      )
      const workEndTime = new Date(
        formatDateForSafari(workHour.pm_end_time.toString())
      )

      const workStartTimeString = formatDateForLocale(workStartTime)
      const workEndTimeString = formatDateForLocale(workEndTime)

      // スケジュールが就業時間内にあるか、またはその一部が重なっているか確認、スケジュールの終了時間が就業開始時間より後かつスケジュールの開始時間が就業終了時間より前
      const isWithinWorkHours =
        scheduleStartTimeString < workEndTimeString &&
        scheduleEndTimeString > workStartTimeString

      return isWithinWorkHours // 就業時間内またはその一部が重なるスケジュールのみtrueを返す
    })
  }

  // 終日の予定だけをfilterする
  const handleFilterWholeDaySchedule = (
    scheduledDate: string,
    wholeDayFlag: boolean,
    startTime?: string
  ) => {
    const newMeetingRoomArray = meetingRooms.map((meetingRoom) => {
      const scheduleFilteredByDate = meetingRoom.schedule_par_date_list.filter(
        (schedule_par_date) => {
          return schedule_par_date.scheduled_date === scheduledDate
        }
      )[0]

      let filteredSchedules = scheduleFilteredByDate.schedules

      if (wholeDayFlag) {
        filteredSchedules = filterSchedulesByWorkHour(filteredSchedules)
      }
      // wholeDayFlagがfalseの場合は日時でfilterし直す
      else if (!wholeDayFlag) {
        filteredSchedules = scheduleFilteredByDate.schedules.filter(
          (schedule) => {
            const targetStartTime = new Date(
              `${scheduledDate.replace(/-/g, "/")} ${startTime}`
            ).getTime()
            const startTimeToString = schedule.start_time
              .toLocaleString()
              .replace(/-/g, "/")
            const endTimeToString = schedule.end_time
              .toLocaleString()
              .replace(/-/g, "/")
            const startTimeNumber = new Date(startTimeToString).getTime()
            const endTimeNumber = new Date(endTimeToString).getTime()
            return (
              startTimeNumber <= targetStartTime &&
              endTimeNumber > targetStartTime
            )
          }
        )
      }

      return { ...meetingRoom, schedules: filteredSchedules }
    })

    const newSeatArray = seats.map((seat) => {
      const scheduleFilteredByDate = seat.schedule_par_date_list.filter(
        (schedule_par_date) => {
          return schedule_par_date.scheduled_date === scheduledDate
        }
      )[0]

      let filteredSchedule = scheduleFilteredByDate.schedules[0]

      if (wholeDayFlag) {
        const filteredSchedules = filterSchedulesByWorkHour(
          scheduleFilteredByDate.schedules
        )
        filteredSchedule = filteredSchedules[0]
      } else if (!wholeDayFlag) {
        filteredSchedule = scheduleFilteredByDate.schedules.filter(
          (schedule) => {
            const targetStartTime = new Date(
              `${scheduledDate.replace(/-/g, "/")} ${startTime}`
            ).getTime()
            const startTimeToString = schedule.start_time
              .toLocaleString()
              .replace(/-/g, "/")
            const endTimeToString = schedule.end_time
              .toLocaleString()
              .replace(/-/g, "/")
            const startTimeNumber = new Date(startTimeToString).getTime()
            const endTimeNumber = new Date(endTimeToString).getTime()
            return (
              startTimeNumber <= targetStartTime &&
              endTimeNumber > targetStartTime
            )
          }
        )[0]
      }

      return { ...seat, schedule: filteredSchedule }
    })

    setSeats(newSeatArray)
    setMeetingRooms(newMeetingRoomArray)
  }

  // 日付の切り替えやフロアの切り替えをした時にscheduleを選択済みの日時でfilterする
  const handleFilterSchedule = (wholeDayFlag: boolean) => {
    if (wholeDayFlag) {
      handleFilterWholeDaySchedule(formatDate(date), wholeDayFlag) // 終日のチェックを入れたまま日付を切り替えた場合、終日の予定だけ取得する
    } else {
      // 終日のチェックを外した状態で日付を切り替えた場合、日付と時刻をベースに予定をfilterする
      filterSeatSchedules(formatDate(date), time)
      filterMeetingRoomSchedules(formatDate(date), time)
    }
  }

  // 拠点とフロアの切り替え
  const changeSelectValue = (e: SelectChangeEvent<number>) => {
    switch (e.target.name) {
      case "branch_id":
        setFloorId(0)
        return setBranchId(Number(e.target.value))
      case "floor_id":
        return setFloorId(Number(e.target.value))
    }
  }

  // 座席のハイライト表示
  const handleSeatHighLight = (seatId: number) => {
    // ハイライトされている座席が1件でもある場合は処理を中断
    const highLightedSeats = seats.filter((seat) => {
      return seat.is_highlight
    })

    if (highLightedSeats.length > 0) {
      clearTimeout()
    }

    // 指定された座席をハイライト表示する
    const newSeatArray = seats.map((seat) => {
      if (seat.id === seatId) {
        return { ...seat, is_highlight: true }
      } else {
        return seat
      }
    })

    setSeats(newSeatArray)

    // 3秒後にハイライトをリセットする
    const resetSeatsArray = seats.map((seat) => {
      return { ...seat, is_highlight: false }
    })

    setTimeout(() => setSeats(resetSeatsArray), 1500)
  }

  // 会議室のハイライト表示
  const handleMeetingRoomHighLight = (meetingRoomId: number) => {
    // ハイライトされている座席が1件でもある場合は処理を中断
    const highLightedMeetingRooms = meetingRooms.filter((meetingRoom) => {
      return meetingRoom.is_highlight
    })

    if (highLightedMeetingRooms.length > 0) {
      clearTimeout()
    }

    // 指定された座席をハイライト表示する
    const newMeetingRoomArray = meetingRooms.map((meetingRoom) => {
      if (meetingRoom.id === meetingRoomId) {
        return { ...meetingRoom, is_highlight: true }
      } else {
        return meetingRoom
      }
    })

    setMeetingRooms(newMeetingRoomArray)

    // 3秒後にハイライトをリセットする
    const resetMeetingRoomsArray = meetingRooms.map((meetingRoom) => {
      return { ...meetingRoom, is_highlight: false }
    })

    setTimeout(() => setMeetingRooms(resetMeetingRoomsArray), 1500)
  }

  // ステータスタイトル言語切り替え
  type ScheduleStatuses = {
    [key: string]: string
  }

  type Translations = {
    schedule_statuses: ScheduleStatuses
  }

  const getLocalizedStatusText = (status: string) => {
    const translations: Translations =
      language === "en" ? enTranslations : jaTranslations
    return (
      translations.schedule_statuses[status] ||
      translations.schedule_statuses[5]
    )
  }

  // 検索対象者がどこにいるのか調べる
  const handleEmployeePlaceSearch = async (targetEmployeeId: number) => {
    const targetSeat = seats.filter((seat) => {
      return seat.schedule?.userable.id === targetEmployeeId
    })[0]

    const targetMeetingRoom = meetingRooms.filter((meetingRoom) => {
      return meetingRoom.schedules?.some((schedule) => {
        return schedule.userable.id === targetEmployeeId
      })
    })[0]

    if (targetSeat) {
      handleSeatHighLight(targetSeat.id as number)
    } else if (targetMeetingRoom) {
      handleMeetingRoomHighLight(targetMeetingRoom.id as number)
    } else {
      const targetTime = `${formatDate(date)} ${
        fifteenMinutesIntervalHours[timeIndex]
      }`

      const schedule = await searchSchedule({
        employee_id: targetEmployeeId,
        schedule_time: targetTime,
        whole_day_flag: wholeDayFlag,
      })

      let message = ""
      setUserNotExists(true)
      if (floorId > 0) {
        message += `${translations.UserExist}\n`
        if (schedule) {
          if (schedule?.reservable == null) {
            message += `${translations.thisUser} ${getLocalizedStatusText(
              schedule?.schedule_title
            )} ${translations.is}`
          } else {
            message += `${schedule?.reservable.layout.floor.branch.branch_name} ${schedule?.reservable.layout.floor.floor_name} 座席番号：${schedule?.reservable.seat_name}にいます。`
          }
        } else {
          message += translations.NotDesk
        }
      } else {
        message += "拠点とフロアを選択してください。"
      }
      setUserNotExistsMessage(message)
    }
  }

  return {
    branchId,
    setBranchId,
    floorId,
    errorMessages,
    changeSelectValue,
    setLayoutImg,
    layoutImg,
    layout,
    seats,
    setSeats,
    meetingRoom,
    meetingRooms,
    setMeetingRoom,
    setMeetingRooms,
    area,
    areas,
    setAreas,
    sidebarLayoutComponent,
    setSidebarLayoutComponent,
    fetchLayout,
    handleSeatHighLight,
    filterSeatSchedules,
    handleEmployeePlaceSearch,
    filterMeetingRoomSchedules,
    selectedSeat,
    selectedMeetingRoom,
    setSelectedSeat,
    setSelectedMeetingRoom,
    handleFilterWholeDaySchedule,
    date,
    setDate,
    previousDate,
    nextDate,
    previousWeekday,
    nextWeekday,
    weekdayNumber,
    setWeekdayNumber,
    dayOfWeek,
    startDate,
    endDate,
    timeIndex,
    setTimeIndex,
    formatDate,
    setStartDate,
    setEndDate,
    userNotExists,
    userNotExistsMessage,
    setUserNotExists,
    wholeDayFlag,
    setWholeDayFlag,
    loading,
    handleFilterSchedule,
    companyRelations,
    selectedBranch,
  }
}
