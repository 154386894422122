import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import {
  Box,
  styled,
  Grid,
  InputLabel,
  Button,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Modal,
  Checkbox,
} from "@mui/material"

import { useContractPlan } from "../../../models/company/useContractPlan"
import { useCreditCard } from "../../../models/company/useCreditCard"
import { BuyerStatusEnum, usePaid } from "../../../models/company/usePaid"
import { usePaymentMethods } from "../../../models/company/usePaymentMethods"
import { useAgreement } from "../../../models/public/useAgreement"
import { useOpen } from "../../../utils/isOpen"
import { CancelButton } from "../../public/CancelButton"
import { Loading } from "../../public/Loading"
import { ReflectButton } from "../../public/ReflectButton"
import { WarningText } from "../../public/WarningText"

const CustomGridContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2.5, 0),
  borderTop: "1px solid rgba(0, 0, 0, 0.12)",
}))

const CustomGridItem = styled(Grid)(() => ({
  display: "flex",
  alignItems: "center",
}))

const CustomLabel = styled(InputLabel)(() => ({
  fontWeight: "bold",
}))

const CustomButton = styled(Button)(({ theme }) => ({
  width: "50%",
  height: "56px",
  fontSize: theme.spacing(2.5),
  fontWeight: "bold",
  boxShadow: "0px 3px 6px #00000029",
  margin: theme.spacing(2, 0),
}))

const CustomLayoutModal = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: "35%",
}))

const buyerStatusContent = (buyerStatus: BuyerStatusEnum) => {
  switch (buyerStatus) {
    case BuyerStatusEnum.UNDER_EXAMINARION:
      return "審査中になります"
    case BuyerStatusEnum.TRADEABLE:
      return "審査が通りました。取引可能です。"
    case BuyerStatusEnum.NOT_AVAULABLE:
      return "取引可能な状態ではないです。ご不明点ございましたら、お問い合わせフォームからご連絡お願いします。"
    case BuyerStatusEnum.NOT_TRADEABLE:
      return "取引可能な状態ではないです。ご不明点ございましたら、お問い合わせフォームからご連絡お願いします。"
    default:
      return "取引可能な状態ではないです。ご不明点ございましたら、お問い合わせフォームからご連絡お願いします。"
  }
}

export const PaymentMethods = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(true)
  const { isOpen, open, close } = useOpen()
  const { paymentMethods, updatePaymentMethods, setPaymentMethods } =
    usePaymentMethods()

  const { privacyChecked, paidChecked, handlePaidChange, handlePrivacyChange } =
    useAgreement()

  const { contractPlan, fetchContractPlan } = useContractPlan()

  const { onFetchCreditCard, defaultCreditCard } = useCreditCard()

  const { onJudgingPaid, buyerStatus } = usePaid()

  useEffect(() => {
    const judgingPaidRequest = async () => {
      await Promise.all([
        onFetchCreditCard(),
        onJudgingPaid(),
        fetchContractPlan(),
      ])
    }
    judgingPaidRequest().then(() => {
      setLoading(false)
    })
  }, [])

  if (loading) {
    return <Loading type="content" loading={loading} />
  }

  return (
    <>
      <Box>
        <CustomGridContainer container>
          <CustomGridItem item xs={2} sm={2}>
            <CustomLabel htmlFor="payment_method">
              銀行振込（※Paid）
            </CustomLabel>
          </CustomGridItem>
          <Grid item xs={5} sm={5}>
            {BuyerStatusEnum.UNKNOWN === buyerStatus ? (
              <CustomButton
                variant="contained"
                color="primary"
                sx={{ fontSize: "12px", textTransform: "capitalize" }}
                onClick={() => {
                  navigate("/company/settings/payment/paid")
                }}
              >
                登録
              </CustomButton>
            ) : (
              <>
                <Typography variant="h6">
                  {buyerStatusContent(buyerStatus)}
                </Typography>
                <Typography variant="caption">
                  銀行振込のお客様情報の確認、編集等は、別途銀行振込より送付されたメールの案内を確認下さい。
                </Typography>
              </>
            )}
          </Grid>
        </CustomGridContainer>
        {BuyerStatusEnum.TRADEABLE === buyerStatus &&
          defaultCreditCard?.CardNo && (
            <>
              <CustomGridContainer container>
                <CustomGridItem item xs={2} sm={2}>
                  <CustomLabel htmlFor="payment_methods">
                    決済方法を選択する
                  </CustomLabel>
                </CustomGridItem>
                <Grid item xs={10} sm={10} sx={{ display: "flex" }}>
                  <Box>
                    <RadioGroup
                      name="payment_methods"
                      value={paymentMethods}
                      sx={{ display: "flex" }}
                      onChange={(e) =>
                        setPaymentMethods(Number(e.target.value))
                      }
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="銀行振込"
                      />
                    </RadioGroup>
                  </Box>
                  <Box sx={{ marginLeft: "30px" }}>
                    <ReflectButton label="選択する" handleClick={open} />
                  </Box>
                </Grid>
              </CustomGridContainer>
            </>
          )}
        <CustomGridContainer container>
          <CustomGridItem item xs={2} sm={2}>
            <CustomLabel htmlFor="payment_methods">
              現在の支払い方法
            </CustomLabel>
          </CustomGridItem>
          <Grid item xs={10} sm={10}>
            {contractPlan.payment_methods === "credit_card" && (
              <Typography variant="h6">クレジットカード払い</Typography>
            )}
            {contractPlan.payment_methods === "paid" && (
              <Typography variant="h6">銀行振込</Typography>
            )}
            {contractPlan.payment_methods === null && (
              <Typography variant="h6">未登録</Typography>
            )}
          </Grid>
        </CustomGridContainer>
      </Box>
      <Box sx={{ mt: 40 }}>
        <Typography variant="caption">
          ※Paidは株式会社ラクーンフィナシャルが提供する企業間決済サービスです
        </Typography>
      </Box>
      <Modal open={isOpen} onClose={close}>
        <CustomLayoutModal>
          <Box sx={{ padding: "30px 30px 20px" }}>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: "20px",
              }}
              variant="h6"
            >
              お支払い方法を変更してもよろしいでしょうか？
            </Typography>
            <Grid item xs={12} sm={12}>
              {WarningText([
                "◉契約しているプランのお支払い方法も変更されます",
                "◉既に請求書が発行されているお支払い方法は変更されません",
              ])}
            </Grid>
          </Box>
          <Box sx={{ padding: "30px 30px 20px" }}>
            <Grid item xs={12} sm={12}>
              <Checkbox
                checked={privacyChecked}
                onChange={handlePrivacyChange}
              />
              <a
                href="https://workagile.jp/index.php/privacy/"
                target="_blank"
                rel="noreferrer"
              >
                プライバシーポリシー
              </a>
              と
              <a
                href="https://workagile.jp/index.php/terms/"
                target="_blank"
                rel="noreferrer"
              >
                利用規約
              </a>
              に同意します
            </Grid>
            {paymentMethods === 1 && (
              <Grid item xs={12} sm={12}>
                <Checkbox checked={paidChecked} onChange={handlePaidChange} />
                <a
                  href="https://paid.jp/v/contents/pre/guide/rules.jsp"
                  target="_blank"
                  rel="noreferrer"
                >
                  銀行振込の利用規約に同意します
                </a>
              </Grid>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "450px",
                m: "30px auto",
              }}
            >
              <CancelButton label="キャンセル" handleClick={close} />
              <ReflectButton
                label="確定する"
                disabled={
                  !privacyChecked ||
                  !(
                    (paidChecked && paymentMethods === 1) ||
                    paymentMethods === 0
                  )
                }
                handleClick={async (e) => {
                  await updatePaymentMethods()
                  close()
                }}
              />
            </Box>
          </Box>
        </CustomLayoutModal>
      </Modal>
    </>
  )
}
