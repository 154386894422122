import React, { useState, useEffect } from "react"
import { Controller } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import {
  Box,
  styled,
  Grid,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  Typography,
  Checkbox,
} from "@mui/material"

import { BuyerStatusEnum, usePaid } from "../../../models/company/usePaid"
import { useAgreement } from "../../../models/public/useAgreement"
import { prefectureList } from "../../../support/prefecture"
import { AlertError } from "../../public/AlertError"
import { CancelButton } from "../../public/CancelButton"
import { Loading } from "../../public/Loading"
import { ReflectButton } from "../../public/ReflectButton"
import { WarningText } from "../../public/WarningText"

const CustomGridContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2.5, 0),
}))

const CustomGridItem = styled(Grid)(() => ({
  display: "flex",
  alignItems: "center",
}))

const buyerStatusContent = (buyerStatus: BuyerStatusEnum) => {
  switch (buyerStatus) {
    case BuyerStatusEnum.UNDER_EXAMINARION:
      return "審査中になります"
    case BuyerStatusEnum.TRADEABLE:
      return "審査が通りました。取引可能です。"
    case BuyerStatusEnum.NOT_AVAULABLE:
      return "取引可能な状態ではないです。ご不明点ございましたら、お問い合わせフォームからご連絡お願いします。"
    case BuyerStatusEnum.NOT_TRADEABLE:
      return "取引可能な状態ではないです。ご不明点ございましたら、お問い合わせフォームからご連絡お願いします。"
    default:
      return "取引可能な状態ではないです。ご不明点ございましたら、お問い合わせフォームからご連絡お願いします。"
  }
}

export const PaymentPaidForm = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(true)
  const { form, onCreatePaid, paidErrorMessage, onJudgingPaid, buyerStatus } =
    usePaid()

  const { privacyChecked, paidChecked, handlePaidChange, handlePrivacyChange } =
    useAgreement()

  const errors = form.formState.errors

  useEffect(() => {
    const judgingPaidRequest = async () => {
      await onJudgingPaid()
    }
    judgingPaidRequest().then(() => {
      setLoading(false)
    })
  }, [])

  if (loading) {
    return <Loading type="content" loading={loading} />
  }

  return (
    <>
      <AlertError
        isMessageOpen={paidErrorMessage.isOpenMessage}
        closeMessage={paidErrorMessage.closeMessage}
        alertMessage={paidErrorMessage.alertMessage}
      />
      {BuyerStatusEnum.UNKNOWN === buyerStatus ? (
        <>
          {WarningText([
            "銀行振込の登録が必要になります",
            "恐れ入りますが審査結果が出るまで3日間かかります",
          ])}
          <CustomGridContainer container spacing={3}>
            <CustomGridItem item xs={2} sm={2}>
              <InputLabel htmlFor="company_name">会社名 *</InputLabel>
            </CustomGridItem>
            <Grid item xs={4} sm={4}>
              <Controller
                control={form.control}
                name="company_name"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.company_name}
                    helperText={errors.company_name?.message}
                    id="company_name"
                    name="company_name"
                    label="会社名"
                    fullWidth
                    variant="outlined"
                    inputProps={{ maxLength: 50 }}
                  />
                )}
              />
            </Grid>
            <CustomGridItem item xs={2} sm={2}>
              <InputLabel htmlFor="company_name_kana">
                会社名（カナ） *
              </InputLabel>
            </CustomGridItem>
            <Grid item xs={4} sm={4}>
              <Controller
                control={form.control}
                name="company_name_kana"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.company_name_kana}
                    helperText={errors.company_name_kana?.message}
                    id="company_name_kana"
                    name="company_name_kana"
                    label="会社名（カナ）"
                    fullWidth
                    variant="outlined"
                    inputProps={{ maxLength: 50 }}
                  />
                )}
              />
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer container spacing={3}>
            <CustomGridItem item xs={2} sm={2}>
              <InputLabel htmlFor="representative_sei">
                代表者（姓） *
              </InputLabel>
            </CustomGridItem>
            <Grid item xs={4} sm={4}>
              <Controller
                control={form.control}
                name="representative_sei"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.representative_sei}
                    helperText={errors.representative_sei?.message}
                    id="representative_sei"
                    name="representative_sei"
                    label="代表者（姓）"
                    fullWidth
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <CustomGridItem item xs={2} sm={2}>
              <InputLabel htmlFor="representative_mei">
                代表者（名） *
              </InputLabel>
            </CustomGridItem>
            <Grid item xs={4} sm={4}>
              <Controller
                control={form.control}
                name="representative_mei"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.representative_mei}
                    helperText={errors.representative_mei?.message}
                    id="representative_mei"
                    name="representative_mei"
                    label="代表者（名）"
                    fullWidth
                    variant="outlined"
                    inputProps={{ maxLength: 50 }}
                  />
                )}
              />
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer container spacing={3}>
            <CustomGridItem item xs={2} sm={2}>
              <InputLabel htmlFor="representative_sei_kana">
                代表者（姓カナ） *
              </InputLabel>
            </CustomGridItem>
            <Grid item xs={4} sm={4}>
              <Controller
                control={form.control}
                name="representative_sei_kana"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.representative_sei_kana}
                    helperText={errors.representative_sei_kana?.message}
                    id="representative_sei_kana"
                    name="representative_sei_kana"
                    label="代表者（姓カナ）"
                    fullWidth
                    variant="outlined"
                    inputProps={{ maxLength: 50 }}
                  />
                )}
              />
            </Grid>
            <CustomGridItem item xs={2} sm={2}>
              <InputLabel htmlFor="representative_mei_kana">
                代表者（名カナ） *
              </InputLabel>
            </CustomGridItem>
            <Grid item xs={4} sm={4}>
              <Controller
                control={form.control}
                name="representative_mei_kana"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.representative_mei_kana}
                    helperText={errors.representative_mei_kana?.message}
                    id="representative_mei_kana"
                    name="representative_mei_kana"
                    label="代表者（名カナ）"
                    fullWidth
                    variant="outlined"
                    inputProps={{ maxLength: 50 }}
                  />
                )}
              />
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer container spacing={3}>
            <CustomGridItem item xs={2} sm={2}>
              <InputLabel htmlFor="zip_code">郵便番号 *</InputLabel>
            </CustomGridItem>
            <Grid
              item
              container
              xs={10}
              sm={10}
              spacing={1}
              alignItems="center"
            >
              <Grid item xs={3}>
                <Controller
                  control={form.control}
                  name="zip_code_1"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.zip_code_1}
                      helperText={errors.zip_code_1?.message}
                      id="zip_code_1"
                      name="zip_code_1"
                      label="000"
                      fullWidth
                      variant="outlined"
                      inputProps={{ maxLength: 3 }}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>-</Typography>
              </Grid>
              <Grid item xs={3}>
                <Controller
                  control={form.control}
                  name="zip_code_2"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.zip_code_2}
                      helperText={errors.zip_code_2?.message}
                      id="zip_code_2"
                      name="zip_code_2"
                      label="0000"
                      fullWidth
                      variant="outlined"
                      inputProps={{ maxLength: 5 }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer container spacing={3}>
            <CustomGridItem item xs={2} sm={2}>
              <InputLabel htmlFor="prefecture">都道府県 *</InputLabel>
            </CustomGridItem>
            <Grid item xs={4} sm={4}>
              <FormControl sx={{ width: 300 }}>
                <InputLabel htmlFor="prefecture">都道府県</InputLabel>
                <Controller
                  control={form.control}
                  name="prefecture"
                  render={({ field }) => (
                    <Select
                      {...field}
                      error={!!errors.prefecture}
                      id="prefecture"
                      name="prefecture"
                      label="都道府県"
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      inputProps={{ maxLength: 5 }}
                    >
                      <MenuItem disabled={true} key={0} value={""}>
                        都道府県を選択してください
                      </MenuItem>
                      {prefectureList.map((prefecture) => (
                        <MenuItem key={prefecture.id} value={prefecture.name}>
                          {prefecture.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <FormHelperText error={!!errors.prefecture?.message}>
                  {errors.prefecture?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer container spacing={3}>
            <CustomGridItem item xs={2} sm={2}>
              <InputLabel htmlFor="address1">市区町村 *</InputLabel>
            </CustomGridItem>
            <Grid item xs={4} sm={4}>
              <Controller
                control={form.control}
                name="address1"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.address1}
                    helperText={errors.address1?.message}
                    id="address1"
                    name="address1"
                    label="市区町村"
                    fullWidth
                    variant="outlined"
                    inputProps={{ maxLength: 50 }}
                  />
                )}
              />
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer container spacing={3}>
            <CustomGridItem item xs={2} sm={2}>
              <InputLabel htmlFor="address2">町名・番地 *</InputLabel>
            </CustomGridItem>
            <Grid item xs={4} sm={4}>
              <Controller
                control={form.control}
                name="address2"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.address2}
                    helperText={errors.address2?.message}
                    id="address2"
                    name="address2"
                    label="町名・番地"
                    fullWidth
                    variant="outlined"
                    inputProps={{ maxLength: 50 }}
                  />
                )}
              />
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer container spacing={3}>
            <CustomGridItem item xs={2} sm={2}>
              <InputLabel htmlFor="address3">ビル・マンション名</InputLabel>
            </CustomGridItem>
            <Grid item xs={4} sm={4}>
              <Controller
                control={form.control}
                name="address3"
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="address3"
                    name="address3"
                    label="ビル・マンション名"
                    fullWidth
                    variant="outlined"
                    inputProps={{ maxLength: 50 }}
                  />
                )}
              />
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer container spacing={3}>
            <CustomGridItem item xs={2} sm={2}>
              <InputLabel htmlFor="clerk_sei">担当者（姓） *</InputLabel>
            </CustomGridItem>
            <Grid item xs={4} sm={4}>
              <Controller
                control={form.control}
                name="clerk_sei"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.clerk_sei}
                    helperText={errors.clerk_sei?.message}
                    id="clerk_sei"
                    name="clerk_sei"
                    label="担当者（姓）"
                    fullWidth
                    variant="outlined"
                    inputProps={{ maxLength: 50 }}
                  />
                )}
              />
            </Grid>
            <CustomGridItem item xs={2} sm={2}>
              <InputLabel htmlFor="clerk_mei">担当者（名） *</InputLabel>
            </CustomGridItem>
            <Grid item xs={4} sm={4}>
              <Controller
                control={form.control}
                name="clerk_mei"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.clerk_mei}
                    helperText={errors.clerk_mei?.message}
                    id="clerk_mei"
                    name="clerk_mei"
                    label="担当者（名）"
                    fullWidth
                    variant="outlined"
                    inputProps={{ maxLength: 50 }}
                  />
                )}
              />
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer container spacing={3}>
            <CustomGridItem item xs={2} sm={2}>
              <InputLabel htmlFor="clerk_sei_kana">
                担当者（姓カナ） *
              </InputLabel>
            </CustomGridItem>
            <Grid item xs={4} sm={4}>
              <Controller
                control={form.control}
                name="clerk_sei_kana"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.clerk_sei_kana}
                    helperText={errors.clerk_sei_kana?.message}
                    id="clerk_sei_kana"
                    name="clerk_sei_kana"
                    label="担当者（姓カナ）"
                    fullWidth
                    variant="outlined"
                    inputProps={{ maxLength: 50 }}
                  />
                )}
              />
            </Grid>
            <CustomGridItem item xs={2} sm={2}>
              <InputLabel htmlFor="clerk_mei_kana">
                担当者（名カナ） *
              </InputLabel>
            </CustomGridItem>
            <Grid item xs={4} sm={4}>
              <Controller
                control={form.control}
                name="clerk_mei_kana"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.clerk_mei_kana}
                    helperText={errors.clerk_mei_kana?.message}
                    id="clerk_mei_kana"
                    name="clerk_mei_kana"
                    label="担当者（名カナ）"
                    fullWidth
                    variant="outlined"
                    inputProps={{ maxLength: 50 }}
                  />
                )}
              />
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer container spacing={3}>
            <CustomGridItem item xs={2} sm={2}>
              <InputLabel htmlFor="tel">電話番号 *</InputLabel>
            </CustomGridItem>
            <Grid
              item
              container
              xs={10}
              sm={10}
              spacing={1}
              alignItems="center"
            >
              <Grid item xs={3}>
                <Controller
                  control={form.control}
                  name="tel_1"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.tel_1}
                      helperText={errors.tel_1?.message}
                      id="tel_1"
                      name="tel_1"
                      label="市外局番"
                      fullWidth
                      variant="outlined"
                      inputProps={{ maxLength: 4 }}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>-</Typography>
              </Grid>
              <Grid item xs={3}>
                <Controller
                  control={form.control}
                  name="tel_2"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.tel_2}
                      helperText={errors.tel_2?.message}
                      id="tel_2"
                      name="tel_2"
                      label=""
                      fullWidth
                      variant="outlined"
                      inputProps={{ maxLength: 4 }}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>-</Typography>
              </Grid>
              <Grid item xs={3}>
                <Controller
                  control={form.control}
                  name="tel_3"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.tel_3}
                      helperText={errors.tel_3?.message}
                      id="tel_3"
                      name="tel_3"
                      label=""
                      fullWidth
                      variant="outlined"
                      inputProps={{ maxLength: 4 }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer container spacing={3}>
            <CustomGridItem item xs={2} sm={2}>
              <InputLabel htmlFor="fax">FAX番号</InputLabel>
            </CustomGridItem>
            <Grid
              item
              container
              xs={10}
              sm={10}
              spacing={1}
              alignItems="center"
            >
              <Grid item xs={3}>
                <Controller
                  control={form.control}
                  name="fax_1"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="fax_1"
                      name="fax_1"
                      label="（任意）"
                      fullWidth
                      variant="outlined"
                      inputProps={{ maxLength: 4 }}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>-</Typography>
              </Grid>
              <Grid item xs={3}>
                <Controller
                  control={form.control}
                  name="fax_2"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="fax_2"
                      name="fax_2"
                      label=""
                      fullWidth
                      variant="outlined"
                      inputProps={{ maxLength: 4 }}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>-</Typography>
              </Grid>
              <Grid item xs={3}>
                <Controller
                  control={form.control}
                  name="fax_3"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="fax_3"
                      name="fax_3"
                      label=""
                      fullWidth
                      variant="outlined"
                      inputProps={{ maxLength: 4 }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer container spacing={3}>
            <CustomGridItem item xs={2} sm={2}>
              <InputLabel htmlFor="mobile_tel">携帯電話番号</InputLabel>
            </CustomGridItem>
            <Grid
              item
              container
              xs={10}
              sm={10}
              spacing={1}
              alignItems="center"
            >
              <Grid item xs={3}>
                <Controller
                  control={form.control}
                  name="mobile_tel_1"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.mobile_tel_1}
                      helperText={errors.mobile_tel_1?.message}
                      id="mobile_tel_1"
                      name="mobile_tel_1"
                      label="（任意）"
                      variant="outlined"
                      fullWidth
                      inputProps={{ maxLength: 3 }}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>-</Typography>
              </Grid>
              <Grid item xs={3}>
                <Controller
                  control={form.control}
                  name="mobile_tel_2"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.mobile_tel_2}
                      helperText={errors.mobile_tel_2?.message}
                      id="mobile_tel_2"
                      name="mobile_tel_2"
                      label=""
                      variant="outlined"
                      fullWidth
                      inputProps={{ maxLength: 4 }}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>-</Typography>
              </Grid>
              <Grid item xs={3}>
                <Controller
                  control={form.control}
                  name="mobile_tel_3"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.mobile_tel_3}
                      helperText={errors.mobile_tel_3?.message}
                      id="mobile_tel_3"
                      name="mobile_tel_3"
                      label=""
                      variant="outlined"
                      fullWidth
                      inputProps={{ maxLength: 4 }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer container spacing={3}>
            <CustomGridItem item xs={2} sm={2}>
              <InputLabel htmlFor="email">メールアドレス *</InputLabel>
            </CustomGridItem>
            <Grid item xs={4} sm={4}>
              <Controller
                control={form.control}
                name="email"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    id="email"
                    name="email"
                    label="メールアドレス"
                    fullWidth
                    variant="outlined"
                    inputProps={{ maxLength: 100 }}
                  />
                )}
              />
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer container spacing={3}>
            <CustomGridItem item xs={2} sm={2}>
              <InputLabel htmlFor="payment_method">支払い方法 *</InputLabel>
            </CustomGridItem>
            <Grid item xs={3} sm={3}>
              <FormControl sx={{ width: 300 }}>
                <InputLabel htmlFor="prefecture">支払い方法</InputLabel>
                <Controller
                  control={form.control}
                  name="payment_method"
                  render={({ field }) => (
                    <Select
                      {...field}
                      error={!!errors.payment_method}
                      id="payment_method"
                      name="payment_method"
                      label="支払い方法"
                      fullWidth
                      variant="outlined"
                    >
                      <MenuItem disabled={true} key={0} value={0}>
                        支払い方法を選択してください
                      </MenuItem>
                      <MenuItem key={1} value={1}>
                        銀行振込
                      </MenuItem>
                      {/* note: 今後使用する可能性があるため、一旦コメントアウト */}
                      {/* <MenuItem key={2} value={2}>
                        口座振替
                      </MenuItem> */}
                    </Select>
                  )}
                />
                <FormHelperText error={!!errors.payment_method?.message}>
                  {errors.payment_method?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer container spacing={3}>
            <Grid item xs={4} sm={4}>
              <Checkbox checked={paidChecked} onChange={handlePaidChange} />
              <a
                href="https://paid.jp/v/contents/pre/guide/rules.jsp"
                target="_blank"
                rel="noreferrer"
              >
                銀行振込の利用規約に同意します
              </a>
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer container spacing={3}>
            <Grid item xs={4} sm={4}>
              <Checkbox
                checked={privacyChecked}
                onChange={handlePrivacyChange}
              />
              <a
                href="https://workagile.jp/index.php/privacy/"
                target="_blank"
                rel="noreferrer"
              >
                プライバシーポリシー
              </a>
              と
              <a
                href="https://workagile.jp/index.php/terms/"
                target="_blank"
                rel="noreferrer"
              >
                利用規約
              </a>
              に同意します
            </Grid>
          </CustomGridContainer>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "450px",
              m: "30px auto",
            }}
          >
            <CancelButton
              label="戻る"
              handleClick={() => navigate("/company/settings/payment")}
            />
            <ReflectButton
              label="取引先登録"
              disabled={
                !form.formState.isValid || !paidChecked || !privacyChecked
              }
              handleClick={onCreatePaid}
            />
          </Box>
        </>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "500px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            {buyerStatusContent(buyerStatus)}
          </Typography>
        </Box>
      )}
    </>
  )
}
