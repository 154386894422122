import React, { useContext, useEffect, useState } from "react"
import { Controller } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import {
  Button,
  Box,
  Typography,
  styled,
  Grid,
  Modal,
  InputLabel,
  TextField,
  Checkbox,
} from "@mui/material"

import { useCompany } from "../../../models/company/useCompany"
import { useContractPlan } from "../../../models/company/useContractPlan"
import { ContractPlan } from "../../../models/company/useContractPlan/type"
import { BuyerStatusEnum, usePaid } from "../../../models/company/usePaid"
import { useSubscriptionNotifyEmail } from "../../../models/company/useSubscriptionNotifyEmail"
import { useAgreement } from "../../../models/public/useAgreement"
import { RoleContext } from "../../../providers/RoleProvider"
import { jaDateFormat } from "../../../utils/dateTimeFormat"
import { feeYearCalculation } from "../../../utils/feeYearCalculation"
import { useAlertMessage } from "../../../utils/isAlertMessage"
import { useOpen } from "../../../utils/isOpen"
import { AlertError } from "../../public/AlertError"
import { CancelButton } from "../../public/CancelButton"
import { ErrorText } from "../../public/ErrorText"
import { Loading } from "../../public/Loading"
import { ReflectButton } from "../../public/ReflectButton"
import { WarningText } from "../../public/WarningText"
import { WithdrawalModal } from "../uiParts/WithdrawalModal"
import { CompanyNameForm } from "./CompanyNameForm"
import { PaymentAddress } from "./PaymentAddress"

const CustomListBox = styled(Box)(({ theme }) => ({
  height: "100px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  boxShadow: "0px 3px 6px #00000029",
  borderRadius: theme.spacing(1),
  backgroundColor: "#FFFFFF",
  marginBottom: theme.spacing(2),
}))

const ToDoListBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginLeft: theme.spacing(5),
}))

const ToDoTitleBox = styled(Box)(() => ({
  display: "flex",
  width: "210px",
  color: "#707070",
  fontWeight: "bold",
  fontSize: "1rem",
}))

const ToDoContentBox = styled(Box)(() => ({}))

const ToDoListText = styled(Typography)(() => ({
  color: "#707070",
  fontWeight: "bold",
  fontSize: "12px",
}))

const CustomButton = styled(Button)(({ theme }) => ({
  width: "216px",
  height: "40px",
  fontSize: theme.spacing(2),
  letterSpacing: "0.7px",
  fontWeight: "bold",
  boxShadow: "0px 3px 6px #00000029",
  borderRadius: theme.spacing(0.75),
  marginRight: theme.spacing(5),
}))

const CustomLayoutPlanModal = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: "60%",
  borderRadius: "10px",
}))
const CustomLayoutEmailModal = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: "800x",
  borderRadius: "10px",
}))

const CustomGridContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1.75, 0),
  borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  display: "flex",
  alignItems: "center",
}))

const CustomGridItem = styled(Grid)(() => ({
  display: "flex",
  alignItems: "center",
}))

const CustomLabel = styled(InputLabel)(() => ({
  fontWeight: "bold",
}))

const CustomTypography = styled(Typography)(() => ({
  fontWeight: "bold",
}))

const CustomTypographyWindowSize = styled(Typography)(() => ({
  fontWeight: "bold",
  whiteSpace: "nowrap",
}))

const CustomFooter = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(-2),
  textAlign: "right",
  color: "#707070",
  fontWeight: "bold",
}))

const ContentHeader = styled(Typography)(() => ({
  fontWeight: "bold",
  color: "rgba(0, 0, 0, 0.6)",
}))

const ContentText = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  color: "rgba(0, 0, 0, 0.6)",
  textDecoration: "underline",
  marginBottom: theme.spacing(3),
}))

const planTitle = (contractPlan: ContractPlan) => {
  switch (contractPlan.plan.plan_type) {
    case "free":
      return `フリートライアル (${jaDateFormat(
        new Date(contractPlan.active_contract.end_date)
      )}まで)`
    case "monthly":
      return "ベーシックプラン（月間契約）"
    case "year":
      return "ベーシックプラン（年間契約）"
    default:
      return "プランなし"
  }
}

const handleAccountAmount = (accountPrice: number): number => {
  return !isNaN(Number(accountPrice)) ? accountPrice : 0
}

export const ServiceManagement = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(true)
  const { onJudgingPaid, buyerStatus } = usePaid()
  const errorMessageObject = useAlertMessage()
  const { privacyChecked, paidChecked, handlePaidChange, handlePrivacyChange } =
    useAgreement()
  const { subscriptionNotifyEmail, fetchSubscriptionNotifyEmail } =
    useSubscriptionNotifyEmail()
  const { companyName, fetchCompany } = useCompany()

  const { isPaid } = useContext(RoleContext)

  const {
    form,
    contractPlan,
    fetchContractPlan,
    updateAccountCount,
    errorMessages,
  } = useContractPlan()
  const errors = form.formState.errors
  const contractOpen = useOpen()
  const notifyEmailOpen = useOpen()
  const companyNameOpen = useOpen()

  // 年プラン以外で解約している場合、アカウントの数は変更できない
  // 年プランの細かい条件のバリデーションは、バックエンドで処理↓↓↓
  // プラン終了日の1ヶ月前の25日以降にライセンスを変更することはできないようにする
  const isAccountChangeAuthority =
    contractPlan.plan.plan_type === "year" ||
    contractPlan?.withdrawal_application_date == null

  useEffect(() => {
    const planState = async () => {
      await Promise.all([fetchContractPlan(), fetchSubscriptionNotifyEmail()])
      form.setValue("account_count", 0)
    }
    planState().then(() => {
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    fetchCompany()
  }, [fetchCompany])

  useEffect(() => {
    onJudgingPaid()
  }, [])
  return (
    <>
      {loading ? (
        <>
          <Loading type="content" />
        </>
      ) : (
        <>
          <AlertError
            isMessageOpen={errorMessageObject.isOpenMessage}
            closeMessage={errorMessageObject.closeMessage}
            alertMessage={errorMessageObject.alertMessage}
          />
          <CustomListBox>
            <ToDoListBox>
              <ToDoTitleBox>プラン</ToDoTitleBox>
              <ToDoContentBox>
                <ToDoListText>
                  <Box>{`ライセンス数: ${
                    contractPlan.active_contract.account_count
                      ? contractPlan.active_contract.account_count
                      : 0
                  }`}</Box>
                  <Box>{planTitle(contractPlan)}</Box>
                </ToDoListText>
              </ToDoContentBox>
            </ToDoListBox>
            <Box>
              {isPaid && (
                <CustomButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    contractOpen.open()
                  }}
                >
                  登録ID数の変更
                </CustomButton>
              )}
              <CustomButton
                variant="contained"
                color="primary"
                onClick={() => {
                  if (
                    (contractPlan.payment_methods === "paid" &&
                      buyerStatus === BuyerStatusEnum.TRADEABLE) ||
                    contractPlan.payment_methods === "credit_card"
                  ) {
                    navigate("/company/settings/payment/plan")
                  } else if (
                    contractPlan.payment_methods === "paid" &&
                    buyerStatus !== BuyerStatusEnum.TRADEABLE
                  ) {
                    errorMessageObject.handleSetMessage(
                      "銀行振込の審査を通過していないため、プランを変更できません"
                    )
                    errorMessageObject.openMessage()
                  } else {
                    errorMessageObject.handleSetMessage(
                      "お支払い方法を登録してください"
                    )
                    errorMessageObject.openMessage()
                  }
                }}
              >
                プラン変更
              </CustomButton>
            </Box>
          </CustomListBox>
          <CustomListBox>
            <ToDoListBox>
              <ToDoTitleBox>お支払い方法</ToDoTitleBox>
              <ToDoContentBox>
                {contractPlan.payment_methods === "credit_card" && (
                  <ToDoListText>クレジットカード払い</ToDoListText>
                )}
                {contractPlan.payment_methods === "paid" && (
                  <ToDoListText>銀行振込</ToDoListText>
                )}
                {contractPlan.payment_methods === null && (
                  <>
                    <ToDoListText>未登録</ToDoListText>
                    <ToDoListText
                      sx={{ color: "#FF0033", fontWeight: "normal" }}
                    >
                      プラン変更前にお支払い方法をご登録ください
                    </ToDoListText>
                  </>
                )}
              </ToDoContentBox>
            </ToDoListBox>
            <Box>
              <CustomButton
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate("/company/settings/payment/methods")
                }}
              >
                編集
              </CustomButton>
            </Box>
          </CustomListBox>
          <CustomListBox>
            <ToDoListBox>
              <ToDoTitleBox>決済履歴一覧</ToDoTitleBox>
            </ToDoListBox>
            <Box>
              <CustomButton
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate("/company/settings/payment/history")
                }}
              >
                表示
              </CustomButton>
            </Box>
          </CustomListBox>
          <CustomListBox>
            <ToDoListBox>
              <ToDoTitleBox>通知用メールアドレス</ToDoTitleBox>
              <ToDoContentBox>
                <ToDoListText>{subscriptionNotifyEmail}</ToDoListText>
              </ToDoContentBox>
            </ToDoListBox>
            <Box>
              <CustomButton
                variant="contained"
                color="primary"
                onClick={notifyEmailOpen.open}
              >
                変更
              </CustomButton>
            </Box>
          </CustomListBox>
          <CustomListBox>
            <ToDoListBox>
              <ToDoTitleBox>法人名称変更</ToDoTitleBox>
              <ToDoContentBox>
                <ToDoListText>{companyName}</ToDoListText>
              </ToDoContentBox>
            </ToDoListBox>
            <Box>
              <CustomButton
                variant="contained"
                color="primary"
                onClick={companyNameOpen.open}
              >
                変更
              </CustomButton>
            </Box>
          </CustomListBox>

          <CustomListBox>
            <ToDoListBox>
              <ToDoTitleBox>利用開始日</ToDoTitleBox>
              <ToDoContentBox>
                <ToDoListText>
                  {jaDateFormat(contractPlan.active_contract.start_date)}
                </ToDoListText>
              </ToDoContentBox>
            </ToDoListBox>
          </CustomListBox>
          <CustomListBox>
            <ToDoListBox>
              <ToDoTitleBox>利用終了日</ToDoTitleBox>
              <ToDoContentBox>
                <ToDoListText>
                  {contractPlan.active_contract.end_date
                    ? jaDateFormat(contractPlan.active_contract.end_date)
                    : ""}
                </ToDoListText>
              </ToDoContentBox>
            </ToDoListBox>
          </CustomListBox>
          {contractPlan.active_contract.next_charge_date && (
            <CustomListBox>
              <ToDoListBox>
                <ToDoTitleBox>次回課金日</ToDoTitleBox>
                <ToDoContentBox>
                  <ToDoListText>
                    {jaDateFormat(
                      contractPlan.active_contract.next_charge_date
                    )}
                  </ToDoListText>
                </ToDoContentBox>
              </ToDoListBox>
            </CustomListBox>
          )}
          {contractPlan.before_contract && (
            <CustomListBox>
              <ToDoListBox>
                <ToDoTitleBox>次回のプラン</ToDoTitleBox>
                <ToDoContentBox>
                  <ToDoListText>
                    <Box>
                      {`ライセンス数: ${contractPlan.before_contract.account_count}`}
                    </Box>
                    <Box>
                      {`${
                        contractPlan.before_contract.plan_name
                      }の開始予定日: ${jaDateFormat(
                        contractPlan.before_contract.start_date
                      )}`}
                    </Box>
                  </ToDoListText>
                </ToDoContentBox>
              </ToDoListBox>
            </CustomListBox>
          )}
          {contractPlan?.withdrawal_application_date && (
            <CustomListBox>
              <ToDoListBox>
                <ToDoTitleBox>解約申込日</ToDoTitleBox>
                <ToDoContentBox>
                  <ToDoListText>
                    {jaDateFormat(contractPlan?.withdrawal_application_date)}
                  </ToDoListText>
                </ToDoContentBox>
              </ToDoListBox>
            </CustomListBox>
          )}
          <CustomFooter>
            <WithdrawalModal />
          </CustomFooter>
          <Modal open={notifyEmailOpen.isOpen} onClose={notifyEmailOpen.close}>
            <CustomLayoutEmailModal>
              <Box sx={{ padding: "30px 30px 0px" }}>
                <PaymentAddress onClickCancelButton={notifyEmailOpen.close} />
              </Box>
            </CustomLayoutEmailModal>
          </Modal>
          {/* 法人名変更モーダル */}
          <Modal open={companyNameOpen.isOpen} onClose={companyNameOpen.close}>
            <CustomLayoutEmailModal>
              <Box sx={{ padding: "30px 30px 0px" }}>
                <CompanyNameForm onClickCancelButton={companyNameOpen.close} />
              </Box>
            </CustomLayoutEmailModal>
          </Modal>

          <Modal open={contractOpen.isOpen} onClose={contractOpen.close}>
            <CustomLayoutPlanModal>
              <Box sx={{ padding: "30px 30px 20px" }}>
                <Box sx={{ mb: 2 }}>
                  <CustomTypography variant="h6">登録ID数変更</CustomTypography>
                </Box>
                {!contractPlan?.withdrawal_application_date && (
                  <Grid item sx={{ mb: 2 }} xs={12} sm={12}>
                    {WarningText([
                      "・解約を申し込んでいる場合、ライセンス数の変更はできません",
                      "・年間契約の場合、プラン終了日の1ヶ月前の25日前まではライセンス数の変更は可能です",
                    ])}
                  </Grid>
                )}
                <CustomGridContainer container>
                  <CustomGridItem item xs={12} sm={2}>
                    <CustomLabel>利用プラン</CustomLabel>
                  </CustomGridItem>
                  <Grid item xs={4} sm={4}>
                    <CustomTypographyWindowSize>
                      {planTitle(contractPlan)}
                    </CustomTypographyWindowSize>
                  </Grid>
                </CustomGridContainer>
                <CustomGridContainer container>
                  <CustomGridItem xs={2} sm={2}>
                    <CustomLabel>ライセンス数</CustomLabel>
                  </CustomGridItem>
                  <Grid item xs={2} sm={2}>
                    <CustomTypography>
                      {contractPlan.active_contract.account_count}
                    </CustomTypography>
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    &rarr;
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <Controller
                      control={form.control}
                      name="account_count"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={!!errors.account_count}
                          helperText={errors.account_count?.message}
                          required
                          id="account_count"
                          name="account_count"
                          fullWidth
                          value={form.watch("account_count")}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  {contractPlan.plan.plan_type === "year" && (
                    <Grid item xs={5} sm={5}>
                      {WarningText(["年間契約開始時のID数以上"])}
                    </Grid>
                  )}
                </CustomGridContainer>
                <CustomGridContainer container>
                  <CustomGridItem item xs={2} sm={2}>
                    <CustomLabel htmlFor="price">ご利用料金</CustomLabel>
                  </CustomGridItem>
                  <Grid item xs={4} sm={4}>
                    <Box
                      sx={{
                        backgroundColor: "#F7F7F7",
                        padding: "5px 10px",
                        border: "1px solid rgba(0, 0, 0, 0.12)",
                      }}
                    >
                      <ContentHeader>変更前 - 月額</ContentHeader>
                    </Box>
                    <Box
                      sx={{
                        padding: "15px",
                        border: "1px solid rgba(0, 0, 0, 0.12)",
                        maxHeight: "250px",
                      }}
                    >
                      <ContentText>
                        {contractPlan.plan.plan_type === "monthly" && (
                          <p>
                            {`￥${(
                              contractPlan.active_contract.account_count *
                              contractPlan.plan.account_amount
                            ).toLocaleString()}/月`}
                          </p>
                        )}
                        {contractPlan.plan.plan_type === "year" && (
                          <p>
                            {`￥${feeYearCalculation(
                              contractPlan.plan.account_amount,
                              contractPlan.active_contract.account_count
                            ).toLocaleString()}/月`}
                          </p>
                        )}
                      </ContentText>
                    </Box>
                  </Grid>
                  <CustomGridItem justifyContent="center" item xs={2} sm={2}>
                    &rarr;
                  </CustomGridItem>
                  <Grid item xs={4} sm={4}>
                    <Box
                      sx={{
                        backgroundColor: "#F7F7F7",
                        padding: "5px 10px",
                        border: "1px solid rgba(0, 0, 0, 0.12)",
                      }}
                    >
                      <ContentHeader>変更後 - 月額</ContentHeader>
                    </Box>
                    <Box
                      sx={{
                        padding: "15px",
                        border: "1px solid rgba(0, 0, 0, 0.12)",
                        maxHeight: "250px",
                      }}
                    >
                      <ContentText>
                        {contractPlan.plan.plan_type === "monthly" && (
                          <p>
                            {`￥${handleAccountAmount(
                              form.getValues("account_count") *
                                contractPlan.plan.account_amount
                            ).toLocaleString()}/月`}
                          </p>
                        )}
                        {contractPlan.plan.plan_type === "year" && (
                          <p>
                            {`￥${handleAccountAmount(
                              feeYearCalculation(
                                contractPlan.plan.account_amount,
                                form.getValues("account_count")
                              )
                            ).toLocaleString()}/月`}
                          </p>
                        )}
                      </ContentText>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} sx={{ mt: 1 }}>
                    <Typography sx={{ color: "#707070" }} variant="caption">
                      ・価格は全て税込表示です
                      <br />
                      ・24日までに変更された場合、翌月初日から変更されたライセンスでご利用いただけます。今月末までは従前のラインセンス数となりますことをご了承ください。
                      <br />
                      ・25日以降に変更された場合、翌々月初日から変更されたライセンスでご利用いただけます。来月末までは従前のラインセンス数となりますことをご了承ください。
                    </Typography>
                  </Grid>
                </CustomGridContainer>
                <CustomGridContainer container>
                  <Grid item xs={12} sm={12}>
                    <Checkbox
                      checked={privacyChecked}
                      onChange={handlePrivacyChange}
                    />
                    <a
                      href="https://workagile.jp/index.php/privacy/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      プライバシーポリシー
                    </a>
                    と
                    <a
                      href="https://workagile.jp/index.php/terms/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      利用規約
                    </a>
                    に同意します
                  </Grid>
                  {contractPlan.payment_methods === "paid" && (
                    <Grid item xs={12} sm={12}>
                      <Checkbox
                        checked={paidChecked}
                        onChange={handlePaidChange}
                      />
                      <a
                        href="https://paid.jp/v/contents/pre/guide/rules.jsp"
                        target="_blank"
                        rel="noreferrer"
                      >
                        銀行振込の利用規約に同意します
                      </a>
                    </Grid>
                  )}
                </CustomGridContainer>
                <Box
                  sx={{
                    width: "50%",
                    margin: "10px auto",
                  }}
                >
                  {errorMessages.length > 0 && ErrorText(errorMessages)}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "450px",
                    m: "6px auto",
                  }}
                >
                  <CancelButton
                    label="キャンセル"
                    handleClick={contractOpen.close}
                  />
                  <ReflectButton
                    label="更新する"
                    disabled={
                      !(
                        typeof Number(form.getValues("account_count")) ===
                          "number" &&
                        Number(form.getValues("account_count")) > 0
                      ) ||
                      !privacyChecked ||
                      !(
                        (paidChecked &&
                          contractPlan.payment_methods === "paid") ||
                        contractPlan.payment_methods === "credit_card" ||
                        contractPlan.payment_methods === null
                      ) ||
                      !isAccountChangeAuthority
                    }
                    handleClick={async (e) => {
                      if (
                        contractPlan.payment_methods === "paid" &&
                        buyerStatus !== BuyerStatusEnum.TRADEABLE
                      ) {
                        errorMessageObject.handleSetMessage(
                          "銀行振込の審査を通過していないため、プランを変更できません"
                        )
                        errorMessageObject.openMessage()
                        contractOpen.close()
                      } else {
                        await updateAccountCount(
                          Number(form.getValues("account_count"))
                        )
                      }
                    }}
                  />
                </Box>
              </Box>
            </CustomLayoutPlanModal>
          </Modal>
        </>
      )}
    </>
  )
}
