import React, { useMemo, useState } from "react"

import { Box, Tooltip, Typography } from "@mui/material"

import { useLanguage } from "../../../../contexts/LanguageContext"
import { SeatObjectType } from "../../../../models/employee/useLayout/type"
import {
  freeSeatStyle,
  notCheckInSeatStyle,
  unavailableStyle,
  checkInSeatStyle,
} from "../../../../styles/seatStyle"
import enTranslations from "../../../../translations/errorMessage/en"
import jaTranslations from "../../../../translations/errorMessage/ja"
import {
  formatDateForSafari,
  formatScheduleTimeRangeForDisplay,
} from "../../../../utils/dateTimeFormat"

type SeatProps = {
  seat: SeatObjectType
  setSelectedSeat: React.Dispatch<
    React.SetStateAction<SeatObjectType | undefined>
  >
}

// 座席オブジェクト
const SeatObject = (props: SeatProps) => {
  const { seat, setSelectedSeat } = props
  // eslint-disable-next-line
  const [seatTarget, setSeatTarget] = useState<HTMLElement | null>()

  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)

  useMemo(() => {
    setSeatTarget(document.getElementById(String(seat.id)))
  }, [seat.id])

  const onMouseEnter = () => {
    setTooltipOpen(true)
  }

  const onMouseLeave = () => {
    setTooltipOpen(false)
  }

  const generateStyleObject = (x: number, y: number) => {
    let objectStyle = undefined
    if (seat.unavailable_flag) {
      objectStyle = unavailableStyle
    } else if (!seat.schedule) {
      objectStyle = freeSeatStyle
    } else if (seat.schedule.checked_in) {
      objectStyle = checkInSeatStyle
    } else {
      objectStyle = notCheckInSeatStyle
    }
    if (seat.is_highlight) {
      return {
        ...objectStyle,
        top: y - 30,
        left: x - 30,
        fontSize: "60px",
        width: "120px",
        height: "120px",
      }
    } else {
      return { ...objectStyle, top: y, left: x }
    }
  }
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const tooltipText = () => {
    const seatInfo = `${translations.Desk}:${seat.seat_name}\n`
    const employeeName = `${translations.Name}: ${
      seat.schedule?.userable?.last_name ?? ""
    } ${seat.schedule?.userable?.first_name ?? ""}\n`
    const scheduleTitle =
      seat.schedule?.schedule_type === "status"
        ? ""
        : `${translations.Subject}: ${seat.schedule?.schedule_title}\n`
    const scheduleTime = seat.schedule?.whole_day_flag
      ? translations.allDay
      : formatScheduleTimeRangeForDisplay(
          formatDateForSafari(seat.schedule?.start_time as string),
          formatDateForSafari(seat.schedule?.end_time as string)
        )

    return `${seatInfo}${employeeName}${scheduleTitle}${scheduleTime}`
  }

  return (
    <>
      <Box
        id={String(seat.id)}
        className={"seat"}
        sx={
          generateStyleObject(seat.x, seat.y) as React.CSSProperties | undefined
        }
        onClick={() => setSelectedSeat(seat)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {seat.schedule ? (
            <Tooltip
              title={tooltipText()}
              placement="bottom"
              disableInteractive
              open={tooltipOpen}
              componentsProps={{
                tooltip: {
                  sx: {
                    whiteSpace: "pre-wrap",
                    position: "fixed",
                    width: "100px",
                  },
                },
              }}
            >
              {seat.schedule?.image_blob ? (
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                  }}
                  src={seat.schedule.image_blob}
                />
              ) : (
                <Typography
                  sx={{ fontSize: seat.is_highlight ? "40px" : "20px" }}
                  color="inherit"
                  display="flex"
                >
                  <span>{seat.schedule.userable?.last_name?.[0] ?? ""}</span>
                  <span>{seat.schedule.userable?.first_name?.[0] ?? ""}</span>
                </Typography>
              )}
            </Tooltip>
          ) : (
            seat.seat_name
          )}
        </div>
      </Box>
    </>
  )
}

interface Props {
  seat: SeatObjectType
  setSelectedSeat: React.Dispatch<
    React.SetStateAction<SeatObjectType | undefined>
  >
}

/*
  レイアウト画像の登録フォームで席のアイコンが表示される
*/
export const EmployeeLayoutSeat: React.FC<Props> = ({
  seat,
  setSelectedSeat,
}: Props) => {
  return (
    <>
      <SeatObject seat={seat} setSelectedSeat={setSelectedSeat} />
    </>
  )
}
