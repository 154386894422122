import React from "react"

import { Box, Typography, styled } from "@mui/material"

import { theme } from "../../../../config/theme"
import { SortByStatusType } from "../../../../models/mobile/useTeamSearch/type"
import { EmployeeIconSmallWrapper } from "../../../public/EmployeeIconWrapper"
import { MobileErrorText } from "../../../public/MobileErrorText"

const ErrorMessageContainer = styled(Box)(() => ({
  width: "100%",
  margin: "0.8rem 0",
}))

const CustomTypography = styled(Typography)(() => ({
  fontWeight: "bold",
  color: "#454545",
}))

const CustomBox = styled(Box)(() => ({
  minHeight: "30px",
  display: "flex",
  flexWrap: "wrap",
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(2),
}))

interface Props {
  sortByStatusSchedules: SortByStatusType[]
  errorMessages: string[]
}

export const MobileTeamSearch: React.FC<Props> = ({
  sortByStatusSchedules,
  errorMessages,
}: Props) => {
  return (
    <>
      {errorMessages.length !== 0 && (
        <ErrorMessageContainer textAlign="center">
          {MobileErrorText(errorMessages)}
        </ErrorMessageContainer>
      )}
      {sortByStatusSchedules.map((schedule, index) => {
        return (
          <>
            <CustomTypography key={index}>{schedule.status}</CustomTypography>
            {schedule.employees.length > 0 ? (
              <CustomBox>
                {schedule.employees.map((employee, index) => {
                  return (
                    <EmployeeIconSmallWrapper
                      key={index}
                      sx={{ marginRight: "0.5rem", marginBottom: "0.5rem" }}
                    >
                      {employee.userable.icon === null ? (
                        <Typography variant="body2" color="inherit">
                          {employee.userable.last_name?.[0]}
                          {employee.userable.first_name?.[0]}
                        </Typography>
                      ) : (
                        <img
                          src={employee.image_blob}
                          style={{
                            borderRadius: "50%",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      )}
                    </EmployeeIconSmallWrapper>
                  )
                })}
              </CustomBox>
            ) : (
              <CustomBox></CustomBox>
            )}
          </>
        )
      })}
    </>
  )
}
