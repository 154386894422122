import { useCallback, useState } from "react"

import { fetchEmployeeCalendarLayoutRequest } from "../../../../api/employee/layoutRequest"
import { downloadIcon } from "../../../../utils/downLoadIcon"
import { downloadLayoutImage } from "../../../../utils/downloadLayoutImage"
import { WhoIsWheres } from "../useScheduleForm/type"
import {
  Area,
  Areas,
  DragEmployee,
  LayoutRequestType,
  LayoutResponse,
  Loading,
  MeetingRoom,
  MeetingRooms,
  Seat,
  Seats,
} from "./type"

const initialSeat: Seat = {
  id: 0,
  area_id: 0,
  seat_name: "",
  seat_type: "",
  available_time_type: "",
  x: 0,
  y: 0,
  schedule: undefined,
  schedules: [],
  unavailable_flag: false,
  reservable_employee_ids: [],
}

const initialSeats: Seats = []

const initialMeetingRoom: MeetingRoom = {
  id: 0,
  meeting_room_code: "",
  meeting_room_name: "",
  capacity: 0,
  availability_type: "",
  reservable_type: "",
  available_time: 0,
  meeting_room_description: "",
  outside_team_usable: false,
  start_x: 0,
  start_y: 0,
  end_x: 0,
  end_y: 0,
  color_code: "",
  schedules: [],
  all_schedules: [],
  auto_cancel_times_id: 0,
  reservable_employee_ids: [],
}

const initialMeetingRooms: MeetingRooms = []

const initialArea: Area = {
  id: 0,
  area_name: "",
  area_type: "",
  is_reservable: false,
  availability_type: "",
  reservable_type: "",
  area_description: "",
  area_image: "",
  start_x: 0,
  start_y: 0,
  end_x: 0,
  end_y: 0,
  color_code: "",
}

const initialAreas: Areas = []

const initialLayoutResponse: LayoutResponse = {
  branch: {
    branch_name: "",
  },
  floor_name: "",
  layout_image: "",
  x: 0,
  y: 0,
  scale: 0,
  seats: initialSeats,
  meeting_rooms: initialMeetingRooms,
  areas: initialAreas,
}

const initialWhoIsWheres: WhoIsWheres = []

const initialDragEmployee: DragEmployee = {
  id: 0,
  icon: "",
  last_name: "",
  first_name: "",
  email: "",
}

export const loadingState = (isLoading = false) => {
  const [loading, setLoading] = useState<boolean>(isLoading)

  const toggleLoading = useCallback(() => setLoading(!isLoading), [])

  return { loading, toggleLoading }
}

export const seatState = (seatObject: Seat = initialSeat) => {
  const [seat, setSeat] = useState<Seat>(seatObject)

  return { seat, setSeat }
}

export const seatsState = (seatArray: Seats = initialSeats) => {
  const [seats, setSeats] = useState<Seats>(seatArray)

  return { seats, setSeats }
}

export const meetingRoomstate = (
  meetingRoomObject: MeetingRoom = initialMeetingRoom
) => {
  const [meetingRoom, setMeetingRoom] = useState<MeetingRoom>(meetingRoomObject)

  return { meetingRoom, setMeetingRoom }
}

export const meetingRoomsState = (
  meetingRoomArray: MeetingRooms = initialMeetingRooms
) => {
  const [meetingRooms, setMeetingRooms] =
    useState<MeetingRooms>(meetingRoomArray)

  return { meetingRooms, setMeetingRooms }
}

export const areaState = (areaObject: Area = initialArea) => {
  const [area, setArea] = useState<Area>(areaObject)

  return { area, setArea }
}

export const areasState = (areaArray: Areas = initialAreas) => {
  const [areas, setAreas] = useState<Areas>(areaArray)

  return { areas, setAreas }
}

export const renderingFlagState = () => {
  const [renderingFlag, setRenderingFlag] = useState<boolean>(true)
  return { renderingFlag, setRenderingFlag }
}

export const layoutResponseState = (
  layoutObject: LayoutResponse = initialLayoutResponse
) => {
  const [layoutResponse, setLayoutResponse] = useState<Loading<LayoutResponse>>(
    {
      loading: false,
      value: layoutObject,
    }
  )

  return { layoutResponse, setLayoutResponse }
}

export const whoIsWheresState = (
  whoIsWhereArray: WhoIsWheres = initialWhoIsWheres
) => {
  const [whoIsWheres, setWhoIsWheres] = useState<WhoIsWheres>(whoIsWhereArray)

  return { whoIsWheres, setWhoIsWheres }
}

export const dragEmployeeState = (
  dragEmployeeObject: DragEmployee = initialDragEmployee
) => {
  const [dragEmployee, setDragEmployee] =
    useState<DragEmployee>(dragEmployeeObject)

  return { dragEmployee, setDragEmployee }
}

export const fetchLayout = async (
  params: LayoutRequestType,
  setLayout: React.Dispatch<React.SetStateAction<Loading<LayoutResponse>>>,
  companyId: number
) => {
  setLayout({ loading: true, value: initialLayoutResponse })
  try {
    const response = await fetchEmployeeCalendarLayoutRequest(params)
    if (response.data && response.status === 200) {
      await Promise.all(
        response.data.seats.map(async (seat) => {
          if (seat.schedule?.userable?.icon) {
            const resultImage = await downloadIcon(
              companyId,
              seat.schedule.userable.id,
              seat.schedule.userable.icon
            )
            if (seat.schedule.userable) {
              seat.schedule.userable.image_blob = resultImage || undefined
            }
          }
        })
      )

      response.data.meeting_rooms.map(async (meetingRoom) => {
        await Promise.all(
          meetingRoom.schedules.map(async (schedule) => {
            if (schedule.userable?.icon) {
              const resultImage = await downloadIcon(
                companyId,
                schedule.userable.id,
                schedule.userable.icon
              )
              if (schedule.userable) {
                schedule.userable.image_blob = resultImage || undefined
              }
            }
          })
        )
      })

      if (response.data.layout_image) {
        const result = await downloadLayoutImage(
          companyId,
          params.branch_id,
          params.floor_id,
          response.data.layout_image
        )

        if (result) {
          setLayout({
            loading: false,
            value: {
              ...response.data,
              layout_image: URL.createObjectURL(result.Body as Blob),
              x: response.data.x,
              y: response.data.y,
              scale: response.data.scale,
            },
          })
        }
      } else {
        setLayout({
          loading: false,
          value: {
            ...response.data,
          },
        })
      }
    }
  } catch (error) {
    console.log(error)
  }
}
